import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  useGetFinishedReportsQuery,
  useUnlockFinishedReportsMutation,
} from "../../../../../store/apis/DietitianAPis";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";

export default function RecipeCategories() {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const {
    data: finishedReports,
    isLoading: isLoading,
    isError: isGetError,
  } = useGetFinishedReportsQuery();

  const [unlockFinishedReports, { isError: isError, isLoading: isSubmitting }] =
    useUnlockFinishedReportsMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  const onSubmit = () => {
    unlockFinishedReports(selectedRowIds);
  };

  const FinishedReportColumns = [
    {
      field: "facilityName",
      headerName: "Community",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "dietitianName",
      headerName: "Consultant",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "dateOfVisit",
      headerName: "Visit Date",
      flex: 1,
      minWidth: 150,
      valueFormatter: (value) => {
        if (null === value) {
          return "";
        } else {
          return new Date(value).toLocaleDateString();
        }
      },
    },
    {
      field: "dateFinished",
      headerName: "Date Finished",
      flex: 1,
      minWidth: 150,
      valueFormatter: (value) => {
        if (null === value) {
          return "";
        } else {
          return new Date(value).toLocaleDateString();
        }
      },
    },
  ];

  return (
    <>
      {/* <div className="actionsRow"></div> */}
      <BaseDataGrid
        autoHeight={false}
        height={{ md: "54vh", xl: "55vh" }}
        rows={finishedReports}
        loading={isLoading}
        columns={FinishedReportColumns}
        error={isGetError}
        checkboxSelection
        getRowId={(row) => row.visitId}
        onRowSelectionModelChange={(ids) => {
          setSelectedRowIds(ids);
        }}
      />
      <Box sx={{ pt: 2 }}>
        <BaseSubmitButton
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          text={"Unlock Reports"}
        />
      </Box>
      {isError ? (
        <Typography
          sx={{
            color: "error",
          }}>
          There was an error submitting the form, please try again.
        </Typography>
      ) : undefined}
    </>
  );
}
