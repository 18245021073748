import React, { useEffect } from "react";
import { useNutritionalCaresQuery } from "../../../../../../../store/apis/NutritionalCareAPIs";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../../../shared/YesNoDisplay";
import { Box } from "@mui/material";
import { BaseButton } from "../../../../../../shared/BaseButton";
import { SCREEN_CONSTANT } from "../../../constants";
import { Nutrition_ScreenNames } from "../constants";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import {
  useAddVisitReportMutation,
  useGetVisitReportsByIdQuery,
  useUpdateVisitReportMutation,
} from "store/apis/ConsultantApis";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseActionBox from "components/shared/BaseActionBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function NutritionalCares() {
  // states

  const [searchParams, setSearchParams] = useSearchParams();
  const visitReportId = searchParams.get("visitReportId");
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");

  //  useform Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructuring
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  // api Section
  // api to get reports
  const { data, isLoading, error } = useNutritionalCaresQuery(visitReportId, {
    skip: !visitReportId,
  });
  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId);

  // dataGrid Columns

  const columns = [
    {
      field: "residentName",
      minWidth: 125,
      flex: 1,
      headerName: "Resident",
    },
    {
      field: "assessmentType",
      minWidth: 170,
      flex: 1,
      headerName: "Assessment Type",
    },

    {
      field: "dietOrderChangeNeeded",
      minWidth: 170,
      flex: 1,
      headerName: `Diet or Medical Order`,
      cellClassName: "cellCenterAlign",

      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.dietOrderChangeNeeded} />
      ),
    },
    {
      field: "changeToTrayCardNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Meal Card`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.changeToTrayCardNeeded} />
      ),
    },
    {
      field: "changeToCarePlanNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Care Plan`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.changeToCarePlanNeeded} />
      ),
    },
    {
      field: "otherNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Other`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => <YesNoDisplay value={params?.row?.otherNeeded} />,
    },
    {
      field: "noChangeNeeded",
      minWidth: 50,
      flex: 1,
      headerName: `No Change Needed`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.noChangeNeeded} />
      ),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `/Consultant/MyRosters/VisitDetails/AddResidentAssessment?visitPlanId=${visitPlanId}&id=${params.row.id}&visitReportId=${visitReportId}`,
              title: "Resident",
            }}
            deleteProps={{
              entityName: params.row?.residentName,
              apiPath: "deleteNutritionalCare",
            }}
          />
        );
      },
    },
  ];
  const [addVisitReport, { isLoading: addLoading, data: responseData }] =
    useAddVisitReportMutation();

  const [
    updateVisitReport,
    { isLoading: updateLoading, data: responseUpdateData },
  ] = useUpdateVisitReportMutation();
  const visitReportsData =
    responseData || responseUpdateData || visitReportData;

  useEffect(() => {
    if (visitReportsData?.id) {
      setValue("description", visitReportsData?.description);
      if (visitReportId !== visitReportsData.id) {
        searchParams.set("visitReportId", visitReportsData.id);
        setSearchParams(searchParams);
      }
    }
  }, [visitReportsData]);

  const loading = addLoading || updateLoading;
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("visitId", visitId);
    formData.append("visitReportTypeId", 4);
    formData.append("description", data.description);
    formData.append("includeSignatureLine", true);
    if (visitReportsData?.id) {
      formData.append("id", visitReportsData?.id);
      updateVisitReport(formData);
    } else {
      addVisitReport(formData);
    }
  };
  return (
    <Box
      sx={{
        padding: "8px",
      }}>
      <BackButton
        To={`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`}
        iconDisabled={true}
        sx={{ marginBottom: "10px" }}
      />
      <HeaderBox
        visitPlanId={visitPlanId}
        ScreensArray={Nutrition_ScreenNames}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            width: "70%",
          }}>
          <Box
            sx={{
              width: visitReportData?.id ? "30%" : "50%",
              marginRight: 1,
            }}>
            <BaseInput
              name="description"
              id="description"
              label="Report Title"
              size="small"
              control={control}
              errors={errors}
              validationProps={{
                required: REQUIRED_ERROR(),
              }}
              // disabled
            />
          </Box>
          {visitReportsData?.id ? (
            <BaseButton
              colors={"primary"}
              To={`/Consultant/MyRosters/VisitDetails/AddResidentAssessment?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}`}
              endIcon={"add"}
              text={"Add Resident"}
            />
          ) : null}
        </Box>
        <BaseSubmitButton
          onClick={handleSubmit(onSubmit)}
          isSubmitting={loading}
        />
      </Box>
      <Box
        sx={{
          height: "12px",
        }}
      />
      {visitReportsData?.id ? (
        <BaseDataGrid
          rows={data}
          loading={isLoading}
          columns={CreateBaseColumns(columns)}
          error={error}
          autoHeight={true}
        />
      ) : null}
    </Box>
  );
}
