// imports
import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { SymbolsDetails, categoryColorStrips } from "./constants";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import size from "lodash/size";

export const RecipeCard = ({
  dbClickNav = false,
  isDescription,
  isCost,
  data,
  keyIndex,
  setOpenViewModal,
  setRecipeData,
  rows,
  column,
  moveCard,
  index,
  thisMeal,
  isholdingArea,
  categoryStrips = false,
  backroundColor,
  setBackroundColor,
  searchKey,
  setSearchKey,
}) => {
  const finalData = data;
  const ref = useRef(null);
  const [bgColor, setBgColor] = useState("");
  // hook to control drop functionality
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (!isUndefined(item?.rows) && !isUndefined(rows)) {
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex, item);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  // hook to able card for dragging
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "card",
      item: { finalData, index, rows, column, thisMeal, isholdingArea },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [finalData, rows, column]
  );
  dragRef(drop(ref));
  let trueTags = [];

  // this filter find out the keys which have true value
  if (finalData) {
    trueTags = Object?.keys(finalData)?.filter(
      (key) => finalData[key] === true
    );
  } else {
    trueTags = [];
  }
  // filter which used to find out the right symbol
  const Trueicon = trueTags?.flatMap((data) =>
    SymbolsDetails?.filter((item) => item.label === data)
  );
  // find which used to find out the right color
  const categoryColor = categoryColorStrips.find(
    (item) => item.id === finalData?.menuCategoryId
  );
  // function which change the backround color
  const handleClick = () => {
    !isUndefined(rows)
      ? setBackroundColor([
          {
            rows,
            column,
            index,
            recipeId: finalData?.recipeId,
          },
        ])
      : setSearchKey(keyIndex);
  };
  // function which will pop modal on double click
  const doubleClick = () => {
    if (typeof setOpenViewModal === "function") {
      setOpenViewModal(true);
      setRecipeData({ finalData, index });
    }
  };

  const renderIcons = () => {
    return Trueicon?.map((data, index) =>
      data.label === "fr" ||
      data.label === "isBaseRecipe" ||
      data.label === "substituteRecipeAvailable" ||
      data.label === "isSubstituteRecipe" ? (
        <span
          key={index}
          className="material-icons"
          style={{
            color: data?.color,
            fontSize: "16px",
          }}>
          {data?.icon}
        </span>
      ) : null
    );
  };
  // useEffect section
  useEffect(() => {
    setBgColor(() => {
      return (size(backroundColor) > 0 &&
        isEqual(backroundColor[0]?.rows, rows) &&
        isEqual(backroundColor[0].column, column) &&
        isEqual(backroundColor[0]?.recipeId, finalData?.recipeId)) ||
        (isEqual(searchKey, keyIndex) && !isUndefined(searchKey))
        ? "RGBA(0, 188, 190, 0.3)"
        : null;
    });
  }, [backroundColor, searchKey, finalData?.recipeId]);
  // UI Section
  return (<>
    <Box
      ref={ref}
      data-handler-id={handlerId}
      cursor="move"
      onClick={handleClick}
      onDoubleClick={doubleClick}
      {...(dbClickNav
        ? {
            onDoubleClick: () =>
              window.open(
                `/plateFul/Recipes/RecipeDetail?recipeId=${finalData.recipeId}&menuId=-1`,
                "_blank"
              ),
          }
        : null)}
      sx={{
        position: "relative",
        boxShadow: "var(--darkBoxShadow)",
        borderRadius: "8px",
        marginY: "2px",
        opacity: isDragging ? 0.5 : 1,

        backgroundColor:
          (backroundColor &&
            isEqual(backroundColor[0]?.recipeId, finalData?.recipeId)) ||
          isEqual(keyIndex, searchKey)
            ? bgColor
            : null
      }}>
      <Box
        sx={{
          margin: { md: "3px 0px", xl: "5px 0px" },
          fontSize: { md: "12px", xl: "14px" },
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Box
          sx={{
            paddingLeft: "2px",
            width: { md: "80%", xl: "84%" },
            display: "flex",
            alignItems: "center"
          }}>
          {renderIcons()}
          <span>{finalData?.recipeName}</span>
        </Box>

        <Box
          sx={{
            fontSize: { md: "8px", xl: "10px" },
            width: { md: "20%" },
            padding: "auto",
            alignItems: "center",
            fontWeight: "bold",
            marginRight: "3px"
          }}>
          {isCost
            ? finalData?.costPerServing
              ? `$  ${finalData?.costPerServing?.toFixed(2)}`
              : "$0.00"
            : null}
        </Box>
      </Box>
      <Box
        sx={{
          fontSize: { md: "9px", xl: "11px" },
          marginLeft: "2px"
        }}>
        {isDescription && (
          <Typography sx={{ wordWrap: "break-word" }} variant="body">
            {finalData?.description}
          </Typography>
        )}
      </Box>
      {categoryStrips ? (
        <Box
          sx={{
            height: "100%",
            width: "3px",
            backgroundColor: categoryColor?.color,
            position: "absolute",
            top: "0px",
            right: "0px",
            borderRadius: "10px",
          }}
        />
      ) : null}
    </Box>
  </>);
};
