import React from "react";
import { Box } from "@mui/material";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import { useSearchParams } from "react-router-dom";
import { pcpFax_ScreenNames } from "../constants";
import HeaderBox from "components/consultant/shared/HeaderBox";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "components/shared/YesNoDisplay";
import { useGetFacilityDoctorQuery } from "store/apis/NutritionalCareAPIs";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";
import BaseActionBox from "components/shared/BaseActionBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export const ManageDoctor = () => {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const visitReportId = searchParams.get("visitReportId");
  const reportId = searchParams.get("id");

  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId);
  const { facilityId } = visitData || [];

  const {
    data: doctorData,
    isLoading,
    error,
  } = useGetFacilityDoctorQuery(facilityId, { skip: !facilityId });
  const columns = [
    {
      field: "doctor",
      flex: 2,
      headerName: "Doctor",
    },
    {
      field: "office",
      flex: 2,
      headerName: "Office Name",
    },

    {
      field: "faxNumber",
      flex: 1,
      headerName: `Fax Number`,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "active",
      minWidth: 100,
      flex: 1,
      headerName: `Active`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => <YesNoDisplay value={params?.row?.isActive} />,
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={doctorData}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `/Consultant/MyRosters/VisitDetails/AddDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}&doctorId=${params.row.id}`,
              title: "Doctor",
            }}
            deleteProps={{
              entityName: params.row?.doctor,
              apiPath: "deleteFacilityDoctor",
              title: "Doctor",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          padding: "8px",
        }}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={pcpFax_ScreenNames}
        />
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <BaseButton
            To={`/Consultant/MyRosters/VisitDetails/AddDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            marginLeft={"0px !important"}
            text={"Add Doctor"}
            endIcon={"add"}
          />
        </Box>
        <Box
          sx={{
            marginTop: "10px",
          }}>
          <BaseDataGrid
            rows={doctorData}
            loading={isLoading}
            columns={CreateBaseColumns(columns)}
            error={error}
            autoHeight={true}
          />
        </Box>
      </Box>
    </>
  );
};
