import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { GppBad, VerifiedUser } from "@mui/icons-material";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/YesNoDisplay.md)
 */
export default function YesNoDisplay({ value }) {
  const text = value ? "Yes" : "No";
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={"100%"}>
        <Typography variant="p" mr={0.5}>
          {text}
        </Typography>
        <Tooltip title={text}>
          {value ? <VerifiedUser color="primary" /> : <GppBad color="error" />}
        </Tooltip>
      </Box>
    </>
  );
}
