import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";
import {
  GridRowModes,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  useGetVendorProductsQuery,
  useUpdateSingleVendorProductMutation,
} from "../../../../../../store/apis/VendorApis.jsx";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import { useGetUnitsQuery } from "../../../../../../store/apis/UnitApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";

export default function BulkProductEntry() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const vendorId = searchParams.get("id");

  const location = useLocation();
  const vendorName = location.state?.vendorName;

  const {
    data: vendorProducts,
    isLoading: isLoading,
    isError: isError,
  } = useGetVendorProductsQuery(
    { vendorId: vendorId, searchText: debouncedValue },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: unitList } = useGetUnitsQuery("");
  const [
    updateVendorProduct,
    // { isError: isUpdateError, isLoading: isSubmitting },
  ] = useUpdateSingleVendorProductMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (vendorProducts) {
      setRows(vendorProducts?.products);
    }
  }, [vendorProducts]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // const handleDeleteClick = (id) => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow) => {
    updateVendorProduct(newRow);
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    const UnitOptions = unitList.map((unit) => {
      return (
        <option key={unit.id} value={unit.id}>
          {unit.label}
        </option>
      );
    });

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus>
        {UnitOptions}
      </Select>
    );
  }

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  const VendorProductsColumns = [
    {
      field: "description",
      headerName: "Product Description",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "columnOfItemNumber",
      headerName: "Item Number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "columnOfPrice",
      headerName: "Price",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (value) => {
        return `$${value.toFixed(2)}`;
      },
    },
    {
      field: "columnOfPackSize",
      headerName: "Pack Size",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "columnOfQuantity",
      headerName: "Quantity",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (value) => {
        return value.toFixed(2);
      },
    },
    {
      field: "columnOfUnitId",
      headerName: "Unit",
      flex: 1,
      minWidth: 100,
      renderEditCell: renderSelectEditInputCell,
      editable: true,
      valueFormatter: (value) => {
        return unitList
          ? unitList?.find((unit) => unit.id === parseInt(value))?.label
          : "";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <div
            key={id}
            className="editIcon"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick(id)}>
            <span className="material-icons">edit</span>
          </div>,
          // <GridActionsCellItem
          //   key={id}
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isId={true}
            Id={vendorId}
          />
          <Typography
            variant="p"
            style={{ marginTop: "10px", marginLeft: "10px" }}>
            {`Vendor: ${vendorName}`}
          </Typography>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          height: "90%",
          width: "100%",
          "& .MuiInputBase-root": {
            "& input[type=number]": {
              textAlign: "right",
            },
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            bgcolor: "rgb(0, 188, 190, .4)",
          },
        }}>
        <BaseDataGrid
          rows={rows}
          loading={isLoading}
          columns={VendorProductsColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          error={isError}
          autoHeight={false}
          height={{ md: "50dvh", xl: "55dvh" }}
        />
        {/* <DataGrid
          rows={rows}
          autoHeight
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            LoadingOverlay: DataGridSkeleton,
            ErrorOverlay: CustomErrorOverlay,
          }}
          border={0}
          loading={isLoading}
          columns={VendorProductsColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          headerHeight={66}
          rowHeight={66}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 100]}
          pagination
          cellClassName="dataCells"
          error={isError || undefined}
        /> */}
      </Box>
    </>
  );
}
