// Hooks Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// Query and Mutation Imports
import {
  useGetDocumentByIdQuery,
  useAddDocumentMutation,
  useUpdateDocumentMutation,
} from "../../../../../../store/apis/DocumentApi";
// MUI Imports
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";

// Custom Components
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../shared/BaseButton";
import { MANAGE_DOC_FIELDS } from "./forms.constants";
import { setErrorsForProperties } from "components/shared/utils/helpers";
// lodash Imports
import isNil from "lodash/isNil";
import {
  BorderClear,
  Description,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AddManageDocument() {
  // local States
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [searchParams] = useSearchParams();
  const EditId = searchParams.get("id");
  const isEdit = !!EditId;

  // Hooks Declaration
  const navigate = useNavigate();

  // UseForm Hook Declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // Destructuring of method
  const {
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  // Mutation Section
  const [
    addDocument,
    {
      isError: addError,
      isLoading: addLoading,
      isSuccess: addSuccess,
      error: addErrorData,
    },
  ] = useAddDocumentMutation();

  const [
    updateDocument,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      error: updateErrorData,
    },
  ] = useUpdateDocumentMutation();

  // Veriable Declaration
  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const errorData = addErrorData || updateErrorData;

  // Get API Section
  const {
    data: managedocument,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDocumentByIdQuery(EditId, {
    skip: !isEdit,
  });
  const isEditLoading = isEdit && currentFetching;

  // UseEffect Section
  useEffect(() => {
    currentSuccess && !currentFetching && managedocument
      ? reset(managedocument)
      : null;
  }, [managedocument]);

  useEffect(() => {
    if (isError && errorData) {
      setErrorsForProperties({ setError: setError, responseError: errorData });
    }
  }, [isError, errorData]);

  isSuccess ? navigate(-1) : null;

  useEffect(() => {
    if (isEdit) {
      setUploadedFile(managedocument?.url);
      setFileName(managedocument?.url);
    }
  }, [managedocument]);

  // OnSubmit Function will call the mutation to submit form
  const onSubmit = (data) => {
    if (isNil(uploadedFile)) {
      setError("file", { type: "manual", message: "Please select a file" });
      return;
    }
    !data.groupName ? (data["groupName"] = " ") : null;
    const dataToSend = new FormData();
    dataToSend.append("file", uploadedFile);

    for (var property in data) {
      if (property === "file") {
        continue;
      }
      dataToSend.append(property, data[property]);
    }
    isEdit ? updateDocument(dataToSend) : addDocument(dataToSend);
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
    clearErrors("file");
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <FlexBox justifyContent={"start"} my={1}>
          <BaseButton
            text="Back To Document list"
            startIcon={<KeyboardArrowLeft />}
            onClick={() => navigate(-1)}
          />
        </FlexBox>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          paddingY: 1
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}>
            <GeneratedForm
              control={control}
              list={MANAGE_DOC_FIELDS}
              errors={errors}
            />
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Button
                  fullWidth
                  component="label"
                  variant="outlined"
                  startIcon={uploadedFile ? <Description /> : <BorderClear />}
                  // sx={{ marginRight: "1rem" }}
                >
                  {uploadedFile ? `Selected File: ` : `Select File`}
                  <input
                    type="file"
                    accept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .png, .jpeg, .jpg"
                    hidden
                    onChange={handleFileUpload}
                  />
                  {fileName}
                </Button>
                <FormHelperText error={!!errors.file}>
                  {errors.file?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
