import React from "react";
import YesNoDisplay from "components/shared/YesNoDisplay";
import { startCase } from "lodash";

export function CreateBaseColumn({
  field,
  sortable,
  headerName,
  renderCell,
  flex = 1,
  minWidth = 100,
  align,
  headerAlign = "left",
  isBoolean = false,
  valueFormatter = null,
  disableClickEventBubbling,
  disableColumnMenu,
  display = "flex",
  cellClassName,
}) {
  let computedHeaderName = headerName;

  if (!headerName) {
    if (field === "actions") {
      computedHeaderName = "Actions";
    } else {
      computedHeaderName = startCase(field);
    }
  }

  let columnDef = {
    field,
    headerName: computedHeaderName,
    flex,
    minWidth,
    headerAlign,
    align: align || headerAlign || "left",
    display,
    sortable: sortable || field !== "actions",
    disableClickEventBubbling: disableClickEventBubbling || field === "actions",
    disableColumnMenu: disableColumnMenu || field === "actions",
  };

  if (cellClassName) {
    columnDef.cellClassName = cellClassName;
  }

  if (isBoolean) {
    columnDef.renderCell = ({ value }) => {
      return <YesNoDisplay value={value} />;
    };
  } else if (renderCell) {
    columnDef.renderCell = renderCell;
  }

  if (valueFormatter) {
    columnDef.valueFormatter = valueFormatter;

    return columnDef;
  }

  return columnDef;
}

export default function CreateBaseColumns(columns) {
  return columns.map((column) => CreateBaseColumn(column));
}
