import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SINGLESIGNONDEMO",
    forgotPassword: "B2C_1A_PASSWORDRESET",
    logonAs: "B2C_1A_IMPERSONATION",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://diningmanagerb2c.b2clogin.com/diningmanagerb2c.onmicrosoft.com/B2C_1A_SINGLESIGNONDEMO",
    },
    forgotPassword: {
      authority:
        "https://diningmanagerb2c.b2clogin.com/diningmanagerb2c.onmicrosoft.com/B2C_1A_PASSWORDRESET",
    },
    logonAs: {
      authority:
        "https://diningmanagerb2c.b2clogin.com/diningmanagerb2c.onmicrosoft.com/B2C_1A_IMPERSONATION",
    },
  },
  authorityDomain: "diningmanagerb2c.b2clogin.com",
  extraQueryParameters: {
    // eslint-disable-next-line no-undef
    server: process.env.REACT_APP_BASE,
  },
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "bc4d46d7-4f6e-4f3c-be34-78a6c0fb7e16",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: "/home",
    redirectUri: "/",
    // navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage", //"sessionStorage", // "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi/diningmanager.read",
    "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi/diningmanager.write",
  ],
  // eslint-disable-next-line no-undef
  extraQueryParameters: b2cPolicies.extraQueryParameters,
};
export const loginRequestSubs = {
  scopes: [
    "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi-poc/diningmanager.read",
    "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi-poc/diningmanager.write",
  ],
  // eslint-disable-next-line no-undef
  extraQueryParameters: b2cPolicies.extraQueryParameters, //`${process.env.REACT_APP_BASE}`
};
//AI
// export const AIRequest = {
//   scopes: [
//     "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi/diningmanager.read",
//     "https://diningmanagerb2c.onmicrosoft.com/diningmanagerapi/diningmanager.write",
//   ],
//   // eslint-disable-next-line no-undef
//   extraQueryParameters: { server: process.env.REACT_APP_BASE }, //`${process.env.REACT_APP_BASE}`
// };
