import { Box, Grid, Skeleton, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";
import React from "react";
import {
  innerLogsData,
  logsHeaders,
  milageHeaders,
  totalLogData,
  finishedSubmittedDates,
} from "../constants";
import size from "lodash/size";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { theme } from "styles/theme";

const VisitHoursTable = ({
  hasLoading,
  visitHours,
  nextConsult,
  totalMileage,
  totalBillableHour,
  submittedDate,
  finishedDate,

}) => {
  return (<>
    {!hasLoading ? (
      <Box>
        {visitHours?.map((visit, index) => {
          const visitHeaders =
            visit.consultingTypeId === 1 ? milageHeaders : logsHeaders;
          return (
            (<Box key={index}>
              <Grid
                sx={{
                  padding: "10px",
                  backgroundColor: "#F6F6F6",
                  alignItems: "center",
                }}
                container>
                {visitHeaders?.map((item) => {
                  return (
                    (<Grid
                      key={item.id}
                      item
                      xs={item.size}
                      sx={{
                        textAlign: item?.textAlign
                      }}>
                      <Typography
                        variant="h7"
                        sx={{
                          lineHeight: "24px",
                          fontSize: "15px",
                          fontWeight: "700",
                          color: "white"
                        }}>
                        {item.name}
                      </Typography>
                    </Grid>)
                  );
                })}
              </Grid>
              {isEqual(visit?.consultingTypeId, 1) ? (
                <Grid
                  sx={{
                    alignItems: "center",
                  }}
                  container>
                  {milageHeaders?.map((item) => {
                    const visitData =
                      visit[
                        isEqual(item.name, "Mileage") ? "quantity" : item.name
                      ];
                    return (
                      (<Grid
                        key={item.id}
                        item
                        xs={item.size}
                        sx={{
                          padding: "10px",
                          borderRight: item.border ? undefined : "1px solid #00000033 ",
                          textAlign: item?.textAlign
                        }}>
                        <Typography
                          variant="h7"
                          sx={{
                            lineHeight: "24px",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "white"
                          }}>
                          {visitData}
                          {isEqual(item.name, "Mileage") ? " miles" : null}
                        </Typography>
                      </Grid>)
                    );
                  })}
                </Grid>
              ) : (
                <Grid
                  container
                  sx={{
                    borderTop: "1px solid #00000033",
                    borderBottom: "1px solid #00000033",
                    alignItems: "center"
                  }}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      padding: "10px",
                      textAlign: "center"
                    }}>
                    <Typography
                      variant="h7"
                      sx={{
                        lineHeight: "24px",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "white"
                      }}>
                      {visit?.consultingTypeName}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sx={{
                    borderLeft: "1px solid #00000033"
                  }}>
                    <Grid container direction="column">
                      <Grid
                        container
                        sx={{
                          borderBottom: "1px solid #00000033",
                          borderRight: "1px solid #00000033",
                          height: "100%"
                        }}>
                        {innerLogsData.map((itemData) => {
                          const visitData = visit[itemData.name];
                          return (
                            (<Grid
                              key={itemData.id}
                              item
                              xs={itemData.size}
                              sx={{
                                textAlign: itemData.textAlign,
                                padding: "10px",

                                borderRight: itemData.border
                                  ? undefined
                                  : "1px solid #00000033"
                              }}>
                              <Typography
                                variant="h7"
                                sx={{
                                  lineHeight: "24px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "white"
                                }}>
                                {visitData}
                              </Typography>
                            </Grid>)
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>)
          );
        })}

        {!isEqual(size(visitHours), 0) ? (
          <Grid
            sx={{
              backgroundColor: theme.palette.yellow.main,
              alignItems: "center",
              borderTop: "1px solid black",
            }}
            container>
            {totalLogData?.map((item, index) => {
              const visitTotals = {
                nextConsult: nextConsult
                  ? dayjs(nextConsult).format(SCREEN_CONSTANTS.Date_Format)
                  : "",
                totalMileage,
                totalBillableHour,
              };
              const totalLogsValue = visitTotals[item?.id];
              return (
                (<Grid
                  key={index}
                  item
                  xs={item.size}
                  sx={{
                    paddingLeft: "5px",
                    borderRight: item?.border ? undefined : "1px solid #00000033 ",
                    textAlign: item?.textAlign
                  }}>
                  <Typography
                    variant="h7"
                    sx={{
                      lineHeight: "24px",
                      fontSize: "15px",
                      fontWeight: "700",
                      color: "white"
                    }}>
                    {item.name} {totalLogsValue}
                  </Typography>
                </Grid>)
              );
            })}
            {finishedSubmittedDates?.map((item, index) => {
              const visitDates = {
                submittedDate: submittedDate
                  ? dayjs(submittedDate).format(SCREEN_CONSTANTS.DateTime_Format)
                  : "",
                finishedDate: finishedDate ? dayjs(finishedDate).format(SCREEN_CONSTANTS.DateTime_Format) : "",
              };
              const visitDatesValue = visitDates[item?.id];
              return (
                (<Grid
                  key={index}
                  item
                  xs={item.size}
                  sx={{
                    paddingLeft: "5px",
                    borderRight: item?.border ? undefined : "1px solid #00000033 ",
                    borderTop: "3px solid #00000033 ",
                    textAlign: item?.textAlign
                  }}>
                  <Typography
                    variant="h7"
                    sx={{
                      lineHeight: "24px",
                      fontSize: "15px",
                      fontWeight: "700",
                      color: "white"
                    }}>
                    {item.name} {visitDatesValue}
                  </Typography>
                </Grid>)
              );
            })}
          </Grid>
        ) : null}
      </Box>
    ) : (
      <Box>
        {[...Array(3)].map((_, index) => (
          <Box
            key={index}
            sx={{
              padding: "10px",
              backgroundColor: "#F6F6F6",
              marginBottom: "10px",
            }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width="100%" height={24} />
              </Grid>
              <Grid item xs={9}>
                <Skeleton variant="rectangular" width="100%" height={24} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width="100%" height={24} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width="100%" height={24} />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    )}
  </>);
};

export default VisitHoursTable;
