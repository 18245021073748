import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAddFacilityDietitianMutation,
  useGetAssignmentDownloadQuery,
  useGetDietitianUserByUserIdQuery,
  useGetFacilitiesByDietitianQuery,
  useGetFacilityDietitianByIdQuery,
  useUpdateFacilityDietitianMutation,
} from "store/apis/DietitianAPis";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import BaseAutoComplete from "components/shared/baseForm/BaseAutoComplete";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useForm } from "react-hook-form";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useGetByIdDefaultOptionQuery } from "store/apis/GenericApis";
import { useGetFacilitySelectBoxQuery } from "store/apis/FacilityApis";
import { useGetUserSelectBoxQuery } from "store/apis/UserApis";
import { COMMUNITY_DIETITIAN_DEFAULTS } from "./communityDietitian.constants";
import { Link, useSearchParams } from "react-router-dom";
import DeleteButton from "components/shared/DeleteButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { API_ENDPOINTS } from "store/store.constants";
import { useSnackbar } from "notistack";
import NearbyDietitianModal from "./NearbyDietitianModal";
import { debounce } from "lodash";
import { positiveNumber } from "utils/ValidationRegex";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function CommunityDietitian() {
  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const dietitianId = searchParams.get("dietitianId");
  const facilityId = searchParams.get("facilityId");
  const dietitianFacilityId = searchParams.get("id");
  const isEdit = !!dietitianFacilityId;
  const [defaultDFUser, setDefaultDFUser] = useState([]);
  const [defaultDFFacility, setDefaultDFFacility] = useState([]);

  // enqueueSnackbar
  const { enqueueSnackbar } = useSnackbar();

  // useStates
  const [getAssignment, setGetAssignment] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [oldSOW, setOldSOW] = useState(null);
  const [facilityDebouncedSearch, setFacilityDebouncedSearch] = useState();
  const [userDebouncedSearch, setUserDebouncedSearch] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [openNearbyModal, setOpenNearbyModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState(
    COMMUNITY_DIETITIAN_DEFAULTS
  );

  // useForm here
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: defaultValues,
  });

  // watch from useForm
  const watchDietitianId = watch("dietitianId");
  const watchFacilityId = watch("facilityId");
  const watchIsTemporary = watch("isTemporary");
  const watchMilageRate = watch("mileageRatePaidToDietitian");
  const watchHourlyRate = watch("hourlyRatePaidToDietitian");
  const isPositive = watchMilageRate >= 0 && watchHourlyRate >= 0;

  // APIs here
  const {
    data: assignmentURL,
    isFetching: assignmentLoading,
    isSuccess: assignmentSuccess,
  } = useGetAssignmentDownloadQuery(undefined, { skip: !getAssignment });

  const { data: currentDF, isSuccess: currentDFSuccess } =
    useGetFacilityDietitianByIdQuery(dietitianFacilityId, {
      skip: !dietitianFacilityId,
    });

  const {
    data: currentDietitan,
    isFetching: dietitianFetching,
    isError: dietitianError,
    isSuccess: dietitianSuccess,
  } = useGetDietitianUserByUserIdQuery(dietitianId, {
    skip: !dietitianId || dietitianFacilityId,
  });

  const { data: defaultUserOption, isSuccess: defaultUserSuccess } =
    useGetByIdDefaultOptionQuery(
      { url: "userLogin", id: currentDietitan?.dietitianId },
      {
        skip:
          dietitianError ||
          !dietitianSuccess ||
          dietitianFetching ||
          !currentDietitan,
      }
    );

  const { data: defaultFacilityOption, isSuccess: defaultFacilitySuccess } =
    useGetByIdDefaultOptionQuery(
      { url: `${API_ENDPOINTS.FACILITIES}`, id: facilityId },
      {
        skip: !facilityId || dietitianFacilityId,
      }
    );

  const {
    data: facilityList,
    isFetching: loadingFacilities,
    isError: facilitiesError,
  } = useGetFacilitySelectBoxQuery(facilityDebouncedSearch, {
    skip: !facilityDebouncedSearch,
  });

  const {
    data: userList,
    isFetching: loadingUser,
    isError: userError,
  } = useGetUserSelectBoxQuery(userDebouncedSearch, {
    skip: !userDebouncedSearch,
  });

  const [
    addFD,
    {
      isSuccess: addSuccess,
      isLoading: addLoading,
      isError: addIsError,
      error: addError,
    },
  ] = useAddFacilityDietitianMutation();

  const [
    updateFD,
    {
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      isError: updateIsError,
      error: updateError,
    },
  ] = useUpdateFacilityDietitianMutation();

  const {
    data: dFList,
    isFetching: dFListLoading,
    isError: dfError,
  } = useGetFacilitiesByDietitianQuery(watchDietitianId, {
    skip:
      !watchDietitianId || watchDietitianId === 0 || watchDietitianId === "",
    refetchOnMountOrArgChange: true,
  });

  // APIs related
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const isError = addIsError || updateIsError;
  const error = addError || updateError;

  // handleAssignmentDownload function here
  const handleAssignmentDownload = () => {
    if (assignmentSuccess) {
      DownloadLinkHelper(assignmentURL);
      return;
    }
    setGetAssignment(true);
  };

  // file handling here
  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
  };

  // handleEdit functionality
  function handleEdit(data) {
    reset(data);
    setOldSOW(data?.originalFileName);
    setSearchParams({ id: data?.id });
    setUserOptions([{ id: data?.dietitianId, label: data?.dietitianName }]);
    setFacilityOptions([{ id: data?.facilityId, label: data?.facilityName }]);
  }

  // Currency formatting
  const currencyFormatter1 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  const currencyFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3,
  });

  // DataGrid Data
  const dfColumns = [
    {
      field: "isTemporary",
      headerName: "Temp",
      isBoolean: true,
    },
    {
      field: "facilityName",
      headerName: "Community Name",
      minWidth: 300,
    },
    {
      field: "contractedHours",
      headerAlign: "center",
      headerName: "Contracted Hours/Freq",
      minWidth: 200,
      type: "number",
      renderCell: (params) => {
        return (
          <>{`${params.value.toFixed(2)} / ${
            params?.row?.contractMinimumPer
          }`}</>
        );
      },
    },
    {
      field: "mileageRatePaidToDietitian",
      headerAlign: "center",
      headerName: "Mileage",
      type: "number",
      valueFormatter: (value) => currencyFormatter2.format(value),
    },
    {
      field: "hourlyRatePaidToDietitian",
      headerAlign: "center",
      headerName: "Hourly",
      type: "number",
      valueFormatter: (value) => currencyFormatter1.format(value),
    },
    {
      field: "secureFileName",
      headerAlign: "center",
      headerName: "SOW",
      renderCell: (params) => {
        if (params.value === "") {
          return <></>;
        }
        return (
          <Link
            target="_blank"
            // eslint-disable-next-line no-undef
            to={`${process.env.REACT_APP_STORAGE_URL}/dietitiansow/${params.value}`}>
            SOW
          </Link>
        );
      },
    },
    {
      field: "actions",
      minWidth: 50,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}>
            <Button onClick={() => handleEdit(params.row)}>
              <span className="material-icons">edit</span>
            </Button>
            <DeleteButton
              index={params.row.id || 0}
              entityName={params.row["facilityName"] || ""}
              apiPath="deleteFacilityDietitian"
            />
          </Box>
        );
      },
    },
  ];

  // submit function here
  const onSubmit = (data) => {
    data["facilityId"] = watchFacilityId;

    const data2 = new FormData();
    data2.append("facilityId", watchFacilityId);
    data2.append("dietitianId", data?.dietitianId);
    data2.append(
      "mileageRatePaidToDietitian",
      data?.mileageRatePaidToDietitian ?? 0
    );
    data2.append(
      "hourlyRatePaidToDietitian",
      data?.hourlyRatePaidToDietitian ?? 0
    );
    data2.append("isTemporary", data?.isTemporary ?? false);
    if (data?.dateEndTemporary) {
      data2.append(
        "dateEndTemporary",
        dayjs(data?.dateEndTemporary).isValid()
          ? dayjs(data?.dateEndTemporary).format("YYYY-MM-DD")
          : null
      );
    }
    data2.append("file", uploadedFile);

    if (isEdit) {
      data2.append("id", data?.id);
      updateFD(data2);
    } else {
      addFD(data2);
    }
  };

  // useMemo

  const handleFacilitySearch = useMemo(
    () =>
      debounce((query) => {
        setFacilityDebouncedSearch(query);
      }, 400),
    []
  );
  const handleUserSearch = useMemo(
    () =>
      debounce((query) => {
        setUserDebouncedSearch(query);
      }, 400),
    []
  );

  // useEffect
  useEffect(() => {
    if (currentDFSuccess) {
      setDefaultValues(currentDF);
      reset(currentDF);
      setDefaultDFUser([
        { id: currentDF?.dietitianId, label: currentDF?.dietitianName },
      ]);
      setDefaultDFFacility([
        { id: currentDF?.facilityId, label: currentDF?.facilityName },
      ]);
      setUserOptions([
        { id: currentDF?.dietitianId, label: currentDF?.dietitianName },
      ]);
      setFacilityOptions([
        { id: currentDF?.facilityId, label: currentDF?.facilityName },
      ]);
      if (currentDF?.originalFileName && currentDF?.originalFileName !== "") {
        setOldSOW(currentDF?.originalFileName);
      }
    } else {
      if (defaultUserSuccess) {
        setDefaultDFUser(defaultUserOption);
        reset({ dietitianId: defaultUserOption[0]?.id });
      }
      if (defaultFacilitySuccess) {
        setDefaultDFFacility(defaultFacilityOption);
        setValue("facilityId", defaultFacilityOption[0]?.id);
      }
    }

    if (userList) {
      setUserOptions(userList?.filter((item) => item?.dietitian));
    }

    if (facilityList) {
      setFacilityOptions(facilityList);
    }
  }, [
    currentDFSuccess,
    defaultUserSuccess,
    defaultFacilitySuccess,
    userList,
    facilityList,
    currentDF,
    currentDietitan,
  ]);

  useEffect(() => {
    if (isSuccess && !isSubmitting) {
      setSearchParams({
        id: "",
        dietitianId: watchDietitianId,
        facilityId: watchFacilityId,
      });
      // reset(COMMUNITY_DIETITIAN_DEFAULTS);
      setUploadedFile(null);
      setFileName("");
      setOldSOW("");
      setDefaultValues({ id: 0, facilityId: 0 });
    }
  }, [isSuccess, isSubmitting]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Community Consultant ${isEdit ? "updated" : "created"} successfully.`,
        { variant: "success" }
      );
    }
  }, [isSuccess]);

  // Modal Functionality
  const handleDietitianChange = (params) => {
    setDefaultDFUser(() => [
      {
        id: params?.id,
        label: params?.firstName + " " + params?.lastName,
      },
    ]);
  };
  useEffect(() => {
    setValue("dietitianId", defaultDFUser[0]?.id);
  }, [defaultDFUser]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}>
        <BaseButton
          text="All Assignment List"
          startIcon="file_download"
          endIcon={
            assignmentLoading ? (
              <CircularProgress color="warning" size={"1rem"} />
            ) : null
          }
          onClick={handleAssignmentDownload}
        />
      </Box>
      <Grid
        container
        spacing={1.5}
        sx={{
          alignItems: "center",
          textAlign: "center",
          marginTop: "1px",
        }}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <BaseAutoComplete
            options={facilityOptions || []}
            handleSearch={handleFacilitySearch}
            defaultOptions={defaultDFFacility}
            control={control}
            formSelectProps={{
              props: {
                label: "Community",
                id: "facilityId",
                name: "facilityId",
              },
              validationProps: {
                required: REQUIRED_ERROR("Community"),
              },
            }}
            errors={errors}
            loadingError={facilitiesError}
            loading={loadingFacilities}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <BaseButton
            text="Nearby Consultants"
            onClick={() => setOpenNearbyModal(true)}
          />
          {openNearbyModal && (
            <NearbyDietitianModal
              open={openNearbyModal}
              close={() => {
                setOpenNearbyModal(false);
              }}
              handleDietitianChange={handleDietitianChange}
              facilityId={watchFacilityId}
              selectedCheckBox={defaultDFUser}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <BaseAutoComplete
            options={userOptions}
            handleSearch={handleUserSearch}
            defaultOptions={defaultDFUser}
            formSelectProps={{
              props: {
                label: "Consultant",
                id: "dietitianId",
                name: "dietitianId",
              },
              validationProps: {
                required: REQUIRED_ERROR("Consultant"),
              },
            }}
            control={control}
            errors={errors}
            loadingError={userError}
            loading={loadingUser}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1.5}
        sx={{
          marginTop: 0.5,
        }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <BaseInput
            type="number"
            label="Mileage Rate"
            name="mileageRatePaidToDietitian"
            id="mileageRatePaidToDietitian"
            validationProps={{
              required: REQUIRED_ERROR("Mileage Rate"),
              pattern: {
                value: positiveNumber,
                message: "Please enter positive number",
              },
              maxLength: {
                value: 15,
                message: "Maximum 15 digits allowed",
              },
            }}
            control={control}
            errors={errors}
            unit={"$"}
            defaultValue={0}
            min={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <BaseInput
            name="hourlyRatePaidToDietitian"
            id="hourlyRatePaidToDietitian"
            label="Hourly Rate"
            validationProps={{
              required: REQUIRED_ERROR("Hourly Rate"),
              pattern: {
                value: positiveNumber,
                message: "Please enter positive number",
              },
              maxLength: {
                value: 15,
                message: "Maximum 15 digits allowed",
              },
            }}
            control={control}
            errors={errors}
            type={"number"}
            min={0}
            unit={"$"}
            defaultValue={0}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}>
        <BaseCheckBox
          label={"Is Temporary"}
          name={"isTemporary"}
          control={control}
          errors={errors}
        />

        {watchIsTemporary ? (
          <BaseDatePicker
            label={"Temporary End Date"}
            name={"dateEndTemporary"}
            control={control}
            errors={errors}
          />
        ) : null}
      </Box>
      <Grid
        container
        spacing={0.3}
        sx={{
          mt: 1,
        }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            component="label"
            variant="outlined"
            startIcon={
              <span className="material-icons">
                {uploadedFile ? "description" : "border_clear"}
              </span>
            }
            sx={{ marginRight: "1rem" }}>
            {uploadedFile ? `Selected File: ` : `Select File`}
            <input
              type="file"
              accept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .png, .jpeg, .jpg"
              hidden
              onChange={handleFileUpload}
            />
            {fileName}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {oldSOW !== null && oldSOW !== "" ? (
            <Typography variant="p">
              {`Existing SOW file: ${oldSOW}`}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={3}>
          <BaseSubmitButton
            onClick={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            disabled={!isPositive}
            sx={{ marginBottom: 1 }}
          />
        </Grid>
        {isError ? (
          <Grid item xs={12}>
            <Typography
              variant={"p"}
              sx={{
                color: "error",
              }}>
              {error?.data?.messages}
            </Typography>
          </Grid>
        ) : undefined}
        <Grid item xs={12}>
          <BaseDataGrid
            rows={dFList}
            loading={dFListLoading}
            error={dfError}
            columns={CreateBaseColumns(dfColumns)}
            autoHeight={false}
            height={{ md: "24vh", xl: "25vh" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
