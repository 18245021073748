import React from "react";
import Button from "@mui/material/Button";
import { loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    await instance.loginRedirect({ ...loginRequest, prompt: "login" });
    localStorage.setItem("loginAsUsed", "NO");
  };

  return (
    <div>
      <Button onClick={handleLogin} color="inherit">
        Login
      </Button>
    </div>
  );
};
