import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice/AuthSlice";
import storage from "redux-persist/lib/storage";
import { BaseApi } from "./apis/BaseApi";
import persistReducer from "redux-persist/es/persistReducer";
import genericSnackbarReducer from "./slices/snackbarSlice/GenericSnackbarSlice";
import userInfoReducer from "./slices/userSlice/UserSlice";
import changeTrueReducer from "./slices/menuPlannerSlice/LeaveNavigation";
import recipeCategoryReducer from "./slices/plateFulSlice/Recipes";
import seasonAndMenuReducer from "./slices/mealCardSlice/MealCardData";
import dietitionIdReducer from "./slices/consultantSlice/Dietition";
import facilityIdReducer from "./slices/consultantSlice/Facility";
import featureInfoReducer from "./slices/featureSlice/FeatureSlice";
import monthReducer from "./slices/consultantSlice/Month";
import appReducer from "./slices/appSlice/AppSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import mutationSnackbarMiddleware from "./middlewares/mutationSnackbarMiddleware";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "facilityId"],
};

const reducers = combineReducers({
  auth: authReducer,
  [BaseApi.reducerPath]: BaseApi.reducer,
  genericSnackbar: genericSnackbarReducer,
  userInfo: userInfoReducer,
  featureInfo: featureInfoReducer,
  changeTrue: changeTrueReducer,
  recipeCategory: recipeCategoryReducer,
  seasonAndMenu: seasonAndMenuReducer,
  dietitionId: dietitionIdReducer,
  facilityId: facilityIdReducer,
  month: monthReducer,
  app: appReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(BaseApi.middleware)
      .concat(mutationSnackbarMiddleware),
});
