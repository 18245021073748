import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { selectFacilityId } from "../../../../../../store/slices/authSlice/AuthSlice";
import {
  useGetFacilityByIdQuery,
  useGetFacilityCareTypeQuery,
} from "../../../../../../store/apis/FacilityApis";
import { useSelector } from "react-redux";
import styles from "./CommunityInformation.module.css";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "components/admin/admin.overlays";

export const CommunityInformation = () => {
  const [searchParams] = useSearchParams();
  const facilityId =
    searchParams.get("facilityId") || useSelector(selectFacilityId);
  const [isProduct, setisProduct] = useState(false);
  const [isCare, setisCare] = useState(false);
  const [isContacs, setisContacts] = useState(false);
  const [isOther, setisOther] = useState(false);

  const { data: currentFacility, isLoading } = useGetFacilityByIdQuery(
    facilityId,
    {
      skip: !facilityId,
    }
  );

  const { data: careTypes, isLoading: careTypeLoading } =
    useGetFacilityCareTypeQuery(facilityId, {
      skip: !facilityId,
    });

  return (
    <>
      {isLoading ? (
        <EditSkeleton />
      ) : (
        <Box
          sx={{
            margin: "10px",
          }}>
          <Box
            sx={{
              display: "Flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              width: "100%",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "15px",
              }}>
              <Typography variant="h6" component="h4" className={styles.title}>
                {currentFacility?.name}
              </Typography>
            </Box>
          </Box>
          <Typography variant="h6" component="h2" className={styles.title}>
            {currentFacility?.address +
              "  " +
              currentFacility?.city +
              " " +
              currentFacility?.state +
              "  " +
              currentFacility?.zip}
          </Typography>

          <Typography variant="h6" component="h2" className={styles.title}>
            {currentFacility?.phoneNumber}
          </Typography>

          <Typography variant="h6" component="h2" className={styles.title}>
            {currentFacility?.corporateGroup}
          </Typography>
          <Box
            className={classNames(styles.communityInfo, {
              [styles.iscommunityInfo]: isProduct,
            })}
            onClick={() => setisProduct(!isProduct)}>
            <Typography variant="h6" component="h4" className={styles.title}>
              Product/Services
            </Typography>
            <span className="material-icons">
              {isProduct ? "arrow_drop_up" : "arrow_drop_down"}
            </span>
          </Box>
          {isProduct && (
            <Box className={styles.communityContainer}>
              <div className="row">
                <Typography variant="p" component="p">
                  EHR
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}>
                  {currentFacility?.ehrProviderName}
                </Typography>
              </div>
              <div className="row">
                <Typography variant="p" component="p">
                  Vendor
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}>
                  {currentFacility?.vendor}
                </Typography>
              </div>
            </Box>
          )}
          <Box
            className={classNames(styles.communityInfo, {
              [styles.iscommunityInfo]: isCare,
            })}
            onClick={() => setisCare(!isCare)}>
            <Typography variant="h6" component="h4" className={styles.title}>
              Care Types
            </Typography>
            <span className="material-icons">
              {isCare ? "arrow_drop_up" : "arrow_drop_down"}
            </span>
          </Box>
          {isCare && (
            <Box className={styles.communityContainer}>
              {careTypeLoading ? (
                <EditSkeleton />
              ) : (
                careTypes?.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      <Typography
                        variant="p"
                        component="p"
                        className={styles.communityValue}>
                        {item?.careTypeName}
                      </Typography>
                    </div>
                  );
                })
              )}
            </Box>
          )}

          <Box
            className={classNames(styles.communityInfo, {
              [styles.iscommunityInfo]: isContacs,
            })}
            onClick={() => setisContacts(!isContacs)}>
            <Typography variant="h6" component="h4" className={styles.title}>
              Contacts
            </Typography>
            <span className="material-icons">
              {isContacs ? "arrow_drop_up" : "arrow_drop_down"}
            </span>
          </Box>
          {isContacs && (
            <Box className={styles.communityContainer}>
              <div className="row">
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    width: "20%",
                  }}>
                  Administrator
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "38%",
                  }}>
                  {currentFacility?.administrator}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "38%",
                  }}>
                  {currentFacility?.administratorEmail}
                </Typography>
              </div>
              <div className="row">
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    width: "20%",
                  }}>
                  FSS/DSD
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "38%",
                  }}>
                  {currentFacility?.fssDsd}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "38%",
                  }}>
                  {currentFacility?.fssDsdemail}
                </Typography>
              </div>
              <div className="row">
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    width: "20%",
                  }}>
                  Automatic RD Report
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "78%",
                  }}>
                  {currentFacility?.automaticRdreportEmail}
                </Typography>
              </div>
              <div className="row">
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    width: "20%",
                  }}>
                  Region
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "78%",
                  }}>
                  {currentFacility?.region}
                </Typography>
              </div>
              <div className="row">
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    width: "20%",
                  }}>
                  Support
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.communityValue}
                  sx={{
                    width: "78%",
                  }}>
                  {currentFacility?.billingContactEmail}
                </Typography>
              </div>
            </Box>
          )}

          <Box
            className={classNames(styles.communityInfo, {
              [styles.iscommunityInfo]: isOther,
            })}
            onClick={() => setisOther(!isOther)}>
            <Typography variant="h6" component="h4" className={styles.title}>
              Others
            </Typography>
            <span className="material-icons">
              {isOther ? "arrow_drop_up" : "arrow_drop_down"}
            </span>
          </Box>
          {isOther && (
            <Box className={styles.communityContainer}>
              <Grid
                item
                xs={12}
                sx={{
                  ml: 2,
                  mr: 2,
                }}>
                <TableContainer>
                  <Table sx={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Effective</TableCell>
                        <TableCell>Terminated</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"Menus"}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "700" }}>
                          Menus
                        </TableCell>
                        <TableCell style={{ alignItems: "center" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="material-icons tealColor">
                            {currentFacility?.isMenuCustomer
                              ? "verified_user"
                              : "gpp_bad"}
                          </span>
                        </TableCell>
                        <TableCell>
                          {currentFacility?.menuEffectiveDate}
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px",
                              }}>
                              {currentFacility?.mealcardTermDate
                                ? currentFacility?.mealcardTermDate
                                : "" + " Web"}
                              <span
                                className={`material-icons ${
                                  currentFacility?.isMenuWebBased
                                    ? "tealColor"
                                    : "redColor"
                                }`}>
                                {currentFacility?.isMenuWebBased
                                  ? "verified_user"
                                  : "gpp_bad"}
                              </span>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px",
                              }}>
                              {" Printed"}
                              <span
                                className={`material-icons ${
                                  currentFacility?.isMenuPrinted
                                    ? "tealColor"
                                    : "redColor"
                                }`}>
                                {currentFacility?.isMenuPrinted
                                  ? "verified_user"
                                  : "gpp_bad"}
                              </span>
                            </span>
                            {" PRN Menus"}
                            <span
                              className={`material-icons ${
                                currentFacility?.isRecipeOnlyCustomer
                                  ? "tealColor"
                                  : "redColor"
                              }`}>
                              {currentFacility?.isRecipeOnlyCustomer
                                ? "verified_user"
                                : "gpp_bad"}
                            </span>
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow key={"Mealcard"}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "700" }}>
                          Meal card
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="material-icons tealColor">
                            {currentFacility?.isMealCardCustomer
                              ? "verified_user"
                              : "gpp_bad"}
                          </span>
                        </TableCell>
                        <TableCell>
                          {currentFacility?.mealcardEffectiveDate}
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px",
                              }}>
                              {currentFacility?.mealcardTermDate
                                ? currentFacility?.mealcardTermDate
                                : "" + " Web"}
                              <span
                                className={`material-icons ${
                                  currentFacility?.isMealCardWebBased
                                    ? "tealColor"
                                    : "redColor"
                                }`}>
                                {currentFacility?.isMealCardWebBased
                                  ? "verified_user"
                                  : "gpp_bad"}
                              </span>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px",
                              }}>
                              {" CD"}
                              <span
                                className={`material-icons ${
                                  currentFacility?.isMealCardCdbased
                                    ? "tealColor"
                                    : "redColor"
                                }`}>
                                {currentFacility?.isMealCardCdbased
                                  ? "verified_user"
                                  : "gpp_bad"}
                              </span>
                            </span>
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow key={"supper"}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "700" }}>
                          Tableside
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="material-icons tealColor">
                            {currentFacility?.isTablesideCustomer
                              ? "verified_user"
                              : "gpp_bad"}
                          </span>
                        </TableCell>
                        <TableCell>
                          {currentFacility?.tablesideEffectiveDate}
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}>
                            {currentFacility?.tablesideTermDate}{" "}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
