import { useSearchParams, useNavigate } from "react-router-dom";
import {
  useCustomerProfileReportQuery,
  useGetFacilityByIdQuery,
  useMealCardReportQuery,
  useSetAsDefaultFacilityMutation,
  useArchiveFacilityMutation,
  useAddFacilityLogoMutation,
  useUpdateFacilityLogoMutation,
  useDeleteFacilityLogoMutation,
} from "../../../../../../store/apis/FacilityApis";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Check,
  Close,
  Delete,
  Download,
  ExpandMore,
  Image,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFacilityId,
  setFacilityId,
} from "../../../../../../store/slices/authSlice/AuthSlice";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { DMStyles } from "../../../../../../styles/Styles";
import { EditSkeleton } from "../../../../admin.overlays";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import utcPlugin from "dayjs/plugin/utc";
import { BaseButton } from "components/shared/BaseButton";
import { EditButton } from "components/shared/EditButton";
import DeleteButton from "components/shared/DeleteButton";
import { BaseApi } from "store/apis/BaseApi";
import useMsalAccount from "utils/useMsalAccount";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityDetails() {
  const [expanded, setExpanded] = useState("details");
  // const [imageLoading, setImageLoading] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const id = searchParams.get("id");
  const dispatch = useDispatch();

  const { userId } = useMsalAccount();
  const facilityId = useSelector(selectFacilityId);

  const [cpReport, setCpReport] = useState(false);
  const [mcReport, setMcReport] = useState(false);
  dayjs.extend(utcPlugin);

  const {
    data: customerProfileReport,
    isFetching: customerProfileReportFetching,
    isSuccess: customerProfileReportSuccess,
  } = useCustomerProfileReportQuery(id, { skip: !id || !cpReport });

  const {
    data: mealCardReport,
    isFetching: mealCardReportFetching,
    isSuccess: mealCardReportSuccess,
  } = useMealCardReportQuery(id, { skip: !id || !mcReport });

  const [
    changeFacility,
    { isLoading: changeLoading, isSuccess: changeSuccess },
  ] = useSetAsDefaultFacilityMutation();

  const [archiveCommunity] = useArchiveFacilityMutation();

  useEffect(() => {
    if (changeSuccess && !changeLoading) {
      dispatch(setFacilityId(id));
    }
  }, [changeSuccess, changeLoading]);

  const {
    data: community,
    isSuccess: communitySuccess,
    error: communityError,
    isFetching: communityFetching,
  } = useGetFacilityByIdQuery(id, { skip: !id });
  // console.log("community", community);
  useEffect(() => {
    if (communityError?.status === 404) {
      navigate("/admin/communities/communitySetup?search=&showArchived=false");
    }
  }, [communityError]);

  const [addLogo] = useAddFacilityLogoMutation();

  const [updateLogo] = useUpdateFacilityLogoMutation();

  const [deleteLogo] = useDeleteFacilityLogoMutation();

  const PermissionsTable = communitySuccess
    ? [
        {
          id: 1,
          name: "RD Consulting",
          active: <GenerateIcon value={community.isConsultingCustomer} />,
          effective: community.effectiveDate,
          terminated: community.termDate,
          details: "",
        },
        {
          id: 2,
          name: "CDM Consulting",
          active: <GenerateIcon value={community.isCdmconsultingCustomer} />,
          effective: community.cdmeffectiveDate,
          terminated: community.cdmtermDate,
          details: "",
        },
        {
          id: 3,
          name: "Menus",
          active: <GenerateIcon value={community.isMenuCustomer} />,

          effective: community.menuEffectiveDate,
          terminated: community.menuTermDate,
          details: (
            <>
              {<GenerateIcon value={community.isMenuWebBased} />} Web |
              {<GenerateIcon value={community.isMenuPrinted} />} Printed |
              {<GenerateIcon value={community.isRecipeOnlyCustomer} />} PRN
              Menus
            </>
          ),
        },
        {
          id: 4,
          name: "MealCard",
          active: <GenerateIcon value={community.isMealCardCustomer} />,

          effective: community.mealcardEffectiveDate,
          terminated: community.mealcardTermDate,
          details: (
            <>
              {<GenerateIcon value={community.isMealCardWebBased} />} Web
              {/* {<GenerateIcon value={community.isMealCardCdbased} />} CD */}
            </>
          ),
        },
        {
          id: 5,
          name: "TableSide",
          active: <GenerateIcon value={community.isTablesideCustomer} />,

          effective: community.tablesideEffectiveDate,
          terminated: community.tablesideTermDate,
          details: "",
        },
        {
          id: 6,
          name: `EHR Integration: ${community.ehrProviderName ?? "None"}`,
          active: <GenerateIcon value={community.ehrProviderName ?? false} />,

          effective: community.ehrEffectiveDate,
          terminated: community.ehrTermDate,
          details: (
            <>
              <GenerateIcon value={community.allowUpdatesOfNonEhrresidents} />{" "}
              Update non-EHR Residents
            </>
          ),
        },
        // {
        //   id: 6,
        //   name: "Guidelines & Procedures",
        //   active: <GenerateIcon value={community.hasGuidelinesAndProcedures} />,
        //   effective: "",
        //   terminated: "",
        //   details: "",
        // },
        // {
        //   id: 7,
        //   name: "Diet Manual",
        //   active: <GenerateIcon value={community.hasDietManual} />,
        //   effective: "",
        //   terminated: "",
        //   details: "",
        // },
      ]
    : [];

  const PermissionsColumns = [
    { field: "name", flex: 2, headerName: "Name", width: 200 },
    {
      field: "active",
      flex: 1,
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return <GenerateIcon value={params.value} />;
      },
    },
    {
      field: "effective",
      flex: 1,
      headerName: "Effective",
      width: 150,
      renderCell: (params) => (
        <>
          {params.value &&
            dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)}
        </>
      ),
    },
    {
      field: "terminated",
      flex: 1,
      headerName: "Terminated",
      width: 150,
      renderCell: (params) => (
        <>
          {params.value &&
            dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)}
        </>
      ),
    },
    {
      field: "details",
      flex: 4,
      headerName: "Details",
      width: 300,
      renderCell: (params) => {
        return <GenerateIcon value={params.value} />;
      },
    },
  ];

  useEffect(() => {
    if (customerProfileReportSuccess) {
      DownloadLinkHelper(customerProfileReport);
    }
  }, [customerProfileReport]);

  useEffect(() => {
    if (mealCardReportSuccess) {
      DownloadLinkHelper(mealCardReport);
    }
  }, [mealCardReport]);

  function handleCustomerProfileDownload() {
    if (!customerProfileReportFetching && customerProfileReportSuccess) {
      DownloadLinkHelper(customerProfileReport);
      return;
    }
    setCpReport(true);
  }

  function handleMealCardProfileDownload() {
    if (!mealCardReportFetching && mealCardReportSuccess) {
      DownloadLinkHelper(mealCardReport);
      return;
    }
    setMcReport(true);
  }

  function handleSetAsCurrent() {
    changeFacility({ userId: userId, facilityId: id });
    dispatch(BaseApi.util.resetApiState());
  }

  function handleArchiveUnarchive() {
    let data = { facilityId: id, archive: !community.isArchived };
    archiveCommunity(data);
    navigate("/admin/communities/communitySetup");
  }

  function handleDeleteLogo() {
    deleteLogo(id);
  }

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("FacilityId", id);
      formData.append("File", file);

      if (community?.imageUrl) {
        // update
        updateLogo(formData);
      } else {
        // add
        addLogo(formData);
      }
    }
  };
  // console.log("community?.imageUrl ", community?.imageUrl, imageLoading);
  return (
    <>
      {!communityFetching ? (
        communitySuccess && (
          <Grid>
            <FlexBox
              flexDirection={{ md: "row", xs: "column" }}
              justifyContent={"space-between"}>
              <FlexBox
                justifyContent={"start"}
                width={{ md: "30%", xs: "100%" }}
                my={"8px"}>
                <BaseButton
                  variant={"outlined"}
                  text={"Set As Current"}
                  onClick={handleSetAsCurrent}
                  disabled={facilityId?.toString() === id?.toString()}
                  isSubmitting={changeLoading}
                  marginLeft={"0px !important"}
                />
                <BaseButton
                  text={community.isArchived ? "UnArchive" : "Archive"}
                  onClick={handleArchiveUnarchive}
                  marginLeft={"0px !important"}
                  startIcon={community.isArchived ? <Close /> : <Check />}
                />
              </FlexBox>
              <FlexBox
                justifyContent={{
                  md: "end",
                  sm: "space-between",
                }}
                width={{ md: "70%", lg: "50%", xs: "100%" }}>
                <BaseButton
                  text={"Customer Profile"}
                  onClick={handleMealCardProfileDownload}
                  marginLeft={"0px !important"}
                  isSubmitting={customerProfileReportFetching}
                  endIcon={<Download />}
                  colors="error"
                />
                <BaseButton
                  marginLeft={"0px !important"}
                  text={"Meal Card Profile"}
                  onClick={handleCustomerProfileDownload}
                  isSubmitting={mealCardReportFetching}
                  endIcon={<Download />}
                  colors="error"
                />
                <EditButton
                  To={`/admin/communities/communitySetup/addcommunity?id=${id}`}
                />
                <DeleteButton
                  index={id}
                  entityName={community?.name}
                  apiPath={"deleteFacility"}
                />
              </FlexBox>
            </FlexBox>

            {community?.imageUrl ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "300px",
                  height: "15vh",
                  marginY: "15px",
                  borderRadius: "10px",
                  border: `1px solid ${DMStyles.Teal}`,
                }}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="Community Logo"
                  src={community?.imageUrl}
                />
              </Box>
            ) : null}

            {community?.imageUrl ? (
              <FlexBox justifyContent={"start"}>
                <Button
                  component="label"
                  variant="contained"
                  color="info"
                  size="small"
                  className="baseButton"
                  sx={{
                    marginLeft: "0px !important",
                  }}
                  startIcon={<Image />}>
                  <input
                    type="file"
                    accept=".png, .jpg, ,.jpeg, .bmp"
                    hidden
                    onChange={handleFileUpload}
                  />
                  Update Logo
                </Button>
                <BaseButton
                  colors={"info"}
                  text={"Remove Logo"}
                  startIcon={<Delete />}
                  onClick={handleDeleteLogo}
                />
              </FlexBox>
            ) : (
              <FlexBox justifyContent={"start"}>
                <Button
                  component="label"
                  variant="contained"
                  color="info"
                  size="small"
                  sx={{ borderRadius: DMStyles.BorderRadius }}
                  startIcon={<Image />}>
                  <input
                    type="file"
                    accept=".png, .jpg, ,.jpeg, .bmp"
                    hidden
                    onChange={handleFileUpload}
                  />
                  Add Logo
                </Button>
              </FlexBox>
            )}

            <Accordion
              expanded={expanded === "details"}
              onChange={handleChange("details")}>
              <AccordionSummary
                sx={{
                  borderBottom: DMStyles.Border3,
                  boxShadow: DMStyles.TealBoxShadow,
                }}
                expandIcon={<ExpandMore />}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    width: "33%",
                    flexShrink: 0,
                  }}>
                  Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails width={"100%"}>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    padding: "8px",
                  }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: 1,
                      boxShadow: DMStyles.TealBoxShadow,
                    }}>
                    <Detail name={"Address"} value={community.address} />
                    <Detail name={"City"} value={community.city} />
                    <Detail name={"State"} value={community.state} />
                    <Detail name={"Zip"} value={community.zip} />
                    <Detail name={"Longitude"} value={community.longitude} />
                    <Detail name={"Latitude"} value={community.latitude} />
                    <Detail
                      name={"Address unverified"}
                      value={<GenerateIcon value={community.checkAddress} />}
                    />
                    <Detail
                      name={"Corporate Group"}
                      value={community.corporateGroup}
                    />
                    <Detail
                      name={"Purchasing Group"}
                      value={community.purchasingGroup}
                    />
                    <Detail name={"Vendor"} value={community.vendor} />
                    <Detail name={"Base Menu"} value={community.baseMenu} />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "8px",
                      boxShadow: DMStyles.TealBoxShadow,
                    }}>
                    <Detail
                      name={"Phone Number"}
                      value={community.phoneNumber}
                    />
                    <Detail name={"Fax Number"} value={community.faxnumber} />
                    <Detail name={"Time Zone"} value={community.timeZone} />
                    <Detail name={"Region"} value={community.region} />
                    <Detail
                      name={"Regional Manager"}
                      value={community.regionalManager}
                    />
                    <Detail
                      name={"Client Success Team member"}
                      value={community.clientSuccessUser}
                    />
                    <Detail
                      name={"Encrypt Dietitian Reports"}
                      value={
                        <GenerateIcon
                          value={community.encryptDietitianReports}
                        />
                      }
                    />
                    <Detail
                      name={"Perdiem"}
                      value={<GenerateIcon value={community.perdiem} />}
                    />
                    <Detail
                      name={"Contract Returned"}
                      value={
                        <GenerateIcon value={community.contractReturned} />
                      }
                    />
                    <Detail
                      name={"Is Test Community"}
                      value={<GenerateIcon value={community.isTestFacility} />}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "permissions"}
              onChange={handleChange("permissions")}>
              <AccordionSummary
                sx={{
                  borderBottom: DMStyles.Border3,
                  boxShadow: DMStyles.TealBoxShadow,
                }}
                expandIcon={<ExpandMore />}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    width: "33%",
                    flexShrink: 0,
                  }}>
                  Permissions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BaseDataGrid
                  rows={PermissionsTable}
                  columns={PermissionsColumns}
                  loading={communityFetching}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  hideFooter
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "billing"}
              onChange={handleChange("billing")}>
              <AccordionSummary
                sx={{
                  borderBottom: DMStyles.Border3,
                  boxShadow: DMStyles.TealBoxShadow,
                }}
                expandIcon={<ExpandMore />}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    width: "33%",
                    flexShrink: 0,
                  }}>
                  Contacts and Billing
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    padding: "8px",
                  }}>
                  <Grid
                    item
                    md={6}
                    sx={{
                      padding: "8px",
                      boxShadow: DMStyles.TealBoxShadow,
                    }}>
                    <Detail
                      name={"Contracted Hours"}
                      value={community.contractHours}
                    />
                    <Detail
                      name={"Contract Minimum Per"}
                      value={community.contractMinimumPer}
                    />
                    <Detail
                      name={"Administrator"}
                      value={community.administrator}
                    />
                    <Detail
                      name={"Administrator Email"}
                      value={community.administratorEmail}
                    />
                    <Detail name={"FSS/DSD Contact"} value={community.fssDsd} />
                    <Detail
                      name={"FSS/DSD Contact Email"}
                      value={community.fssDsdemail}
                    />
                    <Detail
                      name={"Consulting Bill To"}
                      value={community.consultingBillTo}
                    />
                    <Detail
                      name={"Consulting Group"}
                      value={community.consultingGroupName}
                    />
                    <Detail
                      name={"Billing Contact"}
                      value={community.billingContact}
                    />
                    <Detail
                      name={"Billing Contact Email"}
                      value={community.billingContactEmail}
                    />
                    <Detail
                      name={"Consulting Billing Period"}
                      value={community.billingPeriod}
                    />
                    <Detail
                      name={"Consulting Auto Renewal"}
                      value={<GenerateIcon value={community.autoRenewal} />}
                    />
                    <Detail
                      name={"Consulting Rate Increase"}
                      value={community.rateIncrease}
                    />
                    <Detail
                      name={"Software Subscription Billing Date"}
                      value={
                        dayjs(community.subscriptionBillingDate).isValid()
                          ? dayjs(community.subscriptionBillingDate).format(
                              SCREEN_CONSTANTS.Date_Format
                            )
                          : ""
                      }
                    />
                    <Detail
                      name={"Software Subscription Next Billing Date"}
                      value={
                        dayjs(community.nextSubscriptionBillingDate).isValid()
                          ? dayjs(community.nextSubscriptionBillingDate).format(
                              SCREEN_CONSTANTS.Date_Format
                            )
                          : ""
                      }
                    />
                    <Detail
                      name={"Software Subscription Period"}
                      value={community.subscriptionPeriod}
                    />
                    <Detail
                      name={"Software Subscription Rate Increase"}
                      value={community.subscriptionRateIncrease}
                    />
                    <Detail
                      name={"Automatic RD Report Email"}
                      value={community.automaticRdreportEmail}
                    />
                    <Detail
                      name={"Sage Customer Id"}
                      value={community.peachtreeCustomerId}
                    />
                    <Detail
                      name={"PBJ Identifier"}
                      value={community.pbjidentifier}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{
                      padding: "8px",
                      boxShadow: DMStyles.TealBoxShadow,
                    }}>
                    <Detail
                      name={"No Mileage"}
                      value={<GenerateIcon value={community.noMileage} />}
                    />
                    <Detail
                      name={"Dietitian Mileage Cap (miles)"}
                      value={community.dietitianMileageCap}
                    />
                    <Detail
                      name={"Enable MealCard Photo"}
                      value={<GenerateIcon value={community.photoOnTrayCard} />}
                    />
                    <Detail
                      name={"Menu Group"}
                      value={community.menuGroupName}
                    />
                    <Detail
                      name={"Menu Charge (annual)"}
                      value={community.menuCharge}
                    />
                    <Detail
                      name={"Menu Bill To"}
                      value={community.menuBillTo}
                    />
                    <Detail
                      name={"MealCard Charge (annual)"}
                      value={community.mealCardCharge}
                    />
                    <Detail
                      name={"MealCard Bill To"}
                      value={community.mealCardBillTo}
                    />
                    <Detail
                      name={"EMR Charge (annual)"}
                      value={community.customizationCharge}
                    />
                    <Detail
                      name={"EMR Bill To"}
                      value={community.customizationBillTo}
                    />
                    <Detail
                      name={"TableSide Charge (annual)"}
                      value={community.tableSideCharge}
                    />
                    <Detail
                      name={"TableSide Bill To"}
                      value={community.tableSideBillTo}
                    />
                    <Detail
                      name={"Medical Record Type"}
                      value={community.medicalRecordTypeName}
                    />
                    <Detail
                      name={"Distributor"}
                      value={community.distributorName}
                    />
                    <Detail
                      name={"Distributor Specific Identifier"}
                      value={community.distributorSpecificIdentifier}
                    />
                    <Detail
                      name={"Distributor Specific Identifier Alt"}
                      value={community.distributorSpecificIdentifierAlt}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "notes"}
              onChange={handleChange("notes")}>
              <AccordionSummary
                sx={{
                  borderBottom: DMStyles.Border3,
                  boxShadow: DMStyles.TealBoxShadow,
                }}
                expandIcon={<ExpandMore />}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    width: "33%",
                    flexShrink: 0,
                  }}>
                  Notes
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={5}>
                  <Grid item md={12}>
                    <Detail name={"Notes"} value={community?.comments} />
                    <Detail
                      name={"Software Notes"}
                      value={community?.softwareNotes}
                    />
                    <Detail name={"Menu Notes"} value={community?.menuNotes} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid container spacing={5}>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                  }}>{`Last updated by ${community?.updatedBy} on ${dayjs
                  .utc(community?.lastUpdated)
                  .local()
                  .format(
                    `${SCREEN_CONSTANTS.Date_Format} h:mm:ss A`
                  )}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      ) : (
        <EditSkeleton />
      )}
    </>
  );
}

function Detail({ name, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: DMStyles.Border,
      }}>
      <Typography
        variant="p"
        sx={{
          fontWeight: 700,
          color: "primary",
        }}>
        {name}:
      </Typography>
      <Typography variant="span"> {value}</Typography>
    </Box>
  );
}

function GenerateIcon({ value }) {
  if (typeof value === "object") return value;
  if (value === "") return null;
  if (value) return <Check color="success" />;

  return <Close color="error" />;
}
