import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetFoodTypesQuery } from "../../../../../store/apis/FoodTypesApis";
import { Button, Tooltip, Typography } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PostAdd } from "@mui/icons-material";

export default function FoodTypes() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: foodTypesPagedData,
    isError: isError,
    isLoading: isLoading,
  } = useGetFoodTypesQuery({
    searchTerm: debouncedValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const foodTypes = foodTypesPagedData?.items || [];

  function columnBoolean(param) {
    return (
      <>
        <Typography
          variant="p"
          sx={{
            width: 40,
          }}>
          {param ? "Yes" : "No"}
        </Typography>
        {param ? (
          <Tooltip title="Yes">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons tealColor">
              verified_user
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons redColor">
              gpp_bad
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  const foodTypesColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 3,
      minWidth: 100,
    },
    {
      field: "isSnack",
      headerName: "Snack/Supplement",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return columnBoolean(params?.row?.isSnack);
      },
    },
    {
      field: "defaultUnitName",
      headerName: "Default Unit",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "isDislike",
      headerName: "Dislike",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return columnBoolean(params?.row?.isDislike);
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={foodTypes}
            hasEdit={true}
            editProps={{
              To: `addFoodType?id=${params.row.id}`,
              title: "Food Types",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteFoodType",
              title: "Food Types",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        mb={1}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
        <Button
          component={Link}
          to={"addFoodType"}
          color={"primary"}
          variant="contained"
          endIcon={<PostAdd />}>
          Add New Food Type
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={foodTypes}
        rowCount={foodTypesPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={foodTypesColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
      />
    </>
  );
}
