import React from "react";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import { useGetRecipeDietListQuery } from "../../../../../store/apis/PlateFulRecipeDietApis";
import { Card, Grid } from "@mui/material";

import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { WarningText } from "../../../../shared/WarningText";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";

import "../../PlateFul.css";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";

export default function DietRecipe() {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");
  const facilityId = useSelector(selectFacilityId);

  const {
    data: diet,
    isError: hasError,
    isLoading: loading,
  } = useGetRecipeDietListQuery({ recipeId: recipeId, facilityId: facilityId });

  const isError = hasError;
  const isLoading = loading;

  const dietColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 2,
      renderCell: (params) => {
        return params?.row?.facilityDietName !== null
          ? params?.row?.facilityDietName
          : params?.row?.dietName;
      },
    },
    {
      field: "alternateRecipeName",
      headerName: "Alternate Recipe",
      flex: 3,
      renderCell: (params) => {
        return params?.row?.alternateRecipeId !== null ? (
          <>
            <Link
              target={"_blank"}
              to={`/plateFul/Recipes/RecipeDetail?recipeId=${params?.row?.alternateRecipeId}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params?.row?.alternateRecipeName}
            </Link>
            &nbsp;-&nbsp;{params?.row?.alternateRecipeDescription}
          </>
        ) : (
          "None"
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diet}
            hasEdit={canEdit}
            editProps={{
              To: `addRDiet?recipeId=${recipeId}`,
              title: "Recipe Diet",
            }}
            hasDelete={canEdit}
            deleteProps={{
              entityName: params.row?.dietName,
              apiPath: "deleteRecipeDiet",
              title: "Recipe Diet",
            }}
          />
        );
      },
    },
  ];

  return (<>
    <Card>
      <Grid container spacing={1} sx={{
        padding: 1
      }}>
        {canEdit === true ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <WarningText
                text={`You only need to add an entry here if a particular diet requires a
                        different recipe. An entry next to a diet that says "None"
                        means that this recipe won't be available for that diet, and no
                        alternate will be provided.`}
                width="100%"
                sx={{
                  margin: "0px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BaseButton
                sx={{ margin: "0px !important" }}
                text="Add New"
                To={`addRDiet?recipeId=${recipeId}`}
                endIcon={<PostAdd />}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            autoHeight={false}
            rows={diet}
            columns={dietColumns}
            loading={isLoading}
            error={isError}
            rowHeight={55}
            height={{ md: "48vh", xl: "52vh" }}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Grid>
      </Grid>
    </Card>
  </>);
}
