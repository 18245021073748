import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/EditButton.md)
 */
export const EditButton = ({
  state,
  title,
  index,
  To,
  data,
  icon,
  onClick,
  disabled,
  prefix = "Edit",
}) => {
  return (
    <Button
      variant="contained"
      color="warning"
      disabled={disabled}
      title={`${prefix} ${title || ""}`}
      icon={"true"}
      dark={"true"}
      to={To}
      onClick={onClick}
      state={{
        title: `${prefix} ${title}`,
        data: data?.find((row) => row.id === index),
        ...state,
      }}
      LinkComponent={Link}>
      {icon ? <span className="material-icons">{icon}</span> : <Edit />}
    </Button>
  );
};
