export const logsHeaders = [
  {
    id: 1,
    name: "Consulting Type",
    size: 3,
  },
  {
    id: 2,
    name: "Time In",
    size: 1,
    textAlign: "center",
  },
  {
    id: 3,
    name: "Time Out",
    size: 1,
    textAlign: "center",
  },
  {
    id: 4,
    name: "Total Hours",
    size: 2,
    textAlign: "center",
  },
  {
    id: 5,
    name: "Notes",
    size: 5,
    textAlign: "center",
  },
];
export const milageHeaders = [
  {
    id: 1,
    name: "Mileage",
    size: 7,
  },
  // {
  //   id: 2,
  //   name: "Bill To",
  //   size: 5,
  //   textAlign: "center",
  //   border: true,
  // },
];
export const innerLogsData = [
  {
    id: 1,
    name: "timeIn",
    size: 1.32,
    textAlign: "center",
  },
  {
    id: 2,
    name: "timeOut",
    size: 1.32,
    textAlign: "center",
  },
  {
    id: 3,
    name: "totalHours",
    size: 2.64,
    textAlign: "center",
  },
  {
    id: 4,
    name: "notes",
    size: 6.6,
    textAlign: "center",
    border: true,
  },
];
export const totalLogData = [
  {
    id: "totalMileage",
    name: "Total Miles:",
    textAlign: "center",
    size: 2.5,
  },
  {
    id: "totalBillableHour",
    name: "Total Hours:",
    size: 2.5,
    textAlign: "center",
  },
  {
    size: 4,
  },
  {
    id: "nextConsult",
    name: "Next Consult Date:",
    size: 3,
    // textAlign: "end",
    border: true,
  },
];
export const finishedSubmittedDates = [
  {
    id: "finishedDate",
    name: "Date Finished:",
    size: 6,
    textAlign: "left",

  },
  {
    id: "submittedDate",
    name: "Date Submitted:",
    textAlign: "left",
    size: 6,
    border: true,

  },  
];
