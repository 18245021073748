import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  DIETITIANS,
  FACILITY_DIETITIANS,
  DIETITIAN_DOCUMENTS,
  FINISHED_REPORTS,
  DIETITIAN_USERS,
  DIETITIAN_LICENCES,
  DIETITIAN_TYPES,
  DIETITIAN_ADDITIONAL_REGIONS,
  DIETITIAN_REGIONS,
  VISIT_PLANS,
} = API_ENDPOINTS;

export const DietitianAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDietitianRegionList: builder.query({
      query: () => `/${DIETITIANS}/DietitianRegions`,
      transformResponse: (response) => response.data,
      providesTags: (response) =>
        response
          ? [{ type: DIETITIANS, id: response.id }, DIETITIANS]
          : [DIETITIANS],
    }),
    getDietitianLists: builder.query({
      query: () => `/${DIETITIANS}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getDietitianByName: builder.query({
      query: (searchTerm) =>
        `/${DIETITIANS}/searchbyname?searchText=${searchTerm}`,
    }),
    getDietitianSelectBox: builder.query({
      query: (searchTerm) =>
        `/${DIETITIANS}/searchbyname?searchText=${searchTerm}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: `${res?.corporateGroup || ""} - ${res?.city} / ${
              res?.state
            }`,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: DIETITIANS,
                id,
              })),
              DIETITIANS,
            ]
          : [DIETITIANS],
    }),
    getFacilityDietitianById: builder.query({
      query: (id) => `/${FACILITY_DIETITIANS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: FACILITY_DIETITIANS, id: response.id },
              FACILITY_DIETITIANS,
            ]
          : [FACILITY_DIETITIANS],
    }),
    getAssignmentDownload: builder.query({
      query: () => `/${FACILITY_DIETITIANS}/AllAssignmentsList`,
      transformResponse: (response) => response?.data,
      providesTags: [FACILITY_DIETITIANS],
    }),
    getFacilitiesByDietitian: builder.query({
      query: (dietitianId) =>
        `/${FACILITY_DIETITIANS}/GetFacilityListByDietitian?dietitianId=${dietitianId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_DIETITIANS,
                id,
              })),
              FACILITY_DIETITIANS,
              VISIT_PLANS,
            ]
          : [FACILITY_DIETITIANS],
    }),
    getCloseDietitians: builder.query({
      query: (facilityId) =>
        `/${FACILITY_DIETITIANS}/GetCloseDietitians?id=${facilityId}`,
      transformResponse: (response) =>
        response?.data.map((res) => {
          res["id"] = res.dietitianID;
          res.distance =
            Math.round((res?.distance + Number.EPSILON) * 100) / 100 + " mi";
          return res;
        }),
    }),
    deleteFacilityDietitian: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_DIETITIANS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_DIETITIANS, id: arg.id },
      ],
    }),
    addFacilityDietitian: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_DIETITIANS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_DIETITIANS, id: result?.id },
      ],
    }),
    updateFacilityDietitian: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_DIETITIANS}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_DIETITIANS, id: result?.id },
      ],
    }),
    getUnassignedFacilities: builder.query({
      query: () => `/${FACILITY_DIETITIANS}/GetUnassignedFacilities`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_DIETITIANS,
                id,
              })),
              FACILITY_DIETITIANS,
            ]
          : [FACILITY_DIETITIANS],
    }),
    getFinishedReports: builder.query({
      query: () => `/${DIETITIAN_DOCUMENTS}/FinishedReports`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({
                type: FINISHED_REPORTS,
                id,
              })),
              FINISHED_REPORTS,
            ]
          : [FINISHED_REPORTS],
    }),
    unlockFinishedReports: builder.mutation({
      query: (visitIds) => ({
        url: `/${VISIT_PLANS}/UnlockFinishedReports`,
        method: "POST",
        body: visitIds,
      }),
      invalidatesTags: [FINISHED_REPORTS],
    }),
    getDietitianById: builder.query({
      query: (id) => `/${DIETITIAN_USERS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DIETITIANS, id: response.id }, DIETITIANS]
          : [DIETITIANS],
    }),
    getDietitianUserByUserId: builder.query({
      query: (userId) => `/${DIETITIAN_USERS}/UserId/${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DIETITIAN_USERS, id: response.id }, DIETITIAN_USERS]
          : [DIETITIAN_USERS],
    }),
    addDietitianUser: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_USERS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DIETITIAN_USERS, id: result?.id }],
    }),
    updateDietitianUser: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_USERS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DIETITIAN_USERS, id: result?.id }],
    }),
    getDietitianLicenses: builder.query({
      query: (dietitianId) => `/${DIETITIAN_LICENCES}/${dietitianId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DIETITIAN_LICENCES, id: response.id }, DIETITIAN_LICENCES]
          : [DIETITIAN_LICENCES],
    }),
    addDietitianLicense: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_LICENCES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [DIETITIAN_LICENCES],
    }),
    updateDietitianLicense: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_LICENCES}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [DIETITIAN_LICENCES],
    }),
    deleteDietitianLicense: builder.mutation({
      query: (id) => ({
        url: `/${DIETITIAN_LICENCES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [DIETITIAN_LICENCES],
    }),
    getDietitianTypes: builder.query({
      query: () => `/${DIETITIANS}/DietitianTypes`,
      transformResponse: (response) =>
        response?.data?.map((value) => ({
          id: value.id,
          label: value.displayName,
        })),
      providesTags: [DIETITIAN_TYPES],
    }),
    getDietitianVisitPlan: builder.query({
      query: ({ month, year, dietitionId }) =>
        dietitionId
          ? `/${VISIT_PLANS}?dietitianId=${dietitionId}&month=${month}&year=${year}`
          : `/${VISIT_PLANS}?month=${month}&year=${year}`,
      transformResponse: (response) => response?.data,
      providesTags: [VISIT_PLANS],
    }),
    getDietitianDocuments: builder.query({
      query: (dietitianId) => `/${DIETITIAN_DOCUMENTS}/${dietitianId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: DIETITIAN_DOCUMENTS, id: response?.id },
              DIETITIAN_DOCUMENTS,
            ]
          : [DIETITIAN_DOCUMENTS],
    }),
    uploadDietitianDocument: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_DOCUMENTS}/UploadDocument`,
        method: "POST",
        body: data,
        formData: true,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [
        { type: DIETITIAN_USERS, id: result?.userId },
        DIETITIAN_DOCUMENTS,
      ],
    }),
    deleteDietitianDocument: builder.mutation({
      query: (id) => ({
        url: `/${DIETITIAN_DOCUMENTS}/DeleteDocument?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [DIETITIAN_USERS, DIETITIAN_DOCUMENTS],
    }),
    getUserRegions: builder.query({
      query: (dietitianId) =>
        `/${DIETITIAN_ADDITIONAL_REGIONS}?dietitianId=${dietitianId}`,
      transformResponse: (response) => response?.data,
      providesTags: [DIETITIAN_REGIONS],
    }),
    addUserRegion: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_ADDITIONAL_REGIONS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [DIETITIAN_REGIONS],
    }),
    deleteUserRegion: builder.mutation({
      query: (id) => ({
        url: `/${DIETITIAN_ADDITIONAL_REGIONS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [DIETITIAN_REGIONS],
    }),
    sentReportToDiningRD: builder.mutation({
      query: (id) => {
        return {
          url: `/DietitianReports/SendReportToDiningRD?VisitId=${id}`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
    }),
    submitReports: builder.mutation({
      query: (body) => ({
        url: `/DietitianReports/SubmitReports`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [VISIT_PLANS],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetDietitianRegionListQuery,
  useGetDietitianListsQuery,
  useGetDietitianByNameQuery,
  useGetDietitianVisitPlanQuery,
  useGetDietitianSelectBoxQuery,
  useGetFacilityDietitianByIdQuery,
  useGetAssignmentDownloadQuery,
  useGetFacilitiesByDietitianQuery,
  useGetCloseDietitiansQuery,
  useDeleteFacilityDietitianMutation,
  useAddFacilityDietitianMutation,
  useUpdateFacilityDietitianMutation,
  useGetUnassignedFacilitiesQuery,
  useGetFinishedReportsQuery,
  useUnlockFinishedReportsMutation,
  useGetDietitianByIdQuery,
  useGetDietitianUserByUserIdQuery,
  useAddDietitianUserMutation,
  useUpdateDietitianUserMutation,
  useGetDietitianLicensesQuery,
  useGetDietitianTypesQuery,
  useGetDietitianDocumentsQuery,
  useUploadDietitianDocumentMutation,
  useDeleteDietitianDocumentMutation,
  useGetUserRegionsQuery,
  useAddUserRegionMutation,
  useDeleteUserRegionMutation,
  useAddDietitianLicenseMutation,
  useUpdateDietitianLicenseMutation,
  useSubmitReportsMutation,
  useSentReportToDiningRDMutation,
} = DietitianAPIs;
