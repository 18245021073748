import React, { useState } from "react";
import {
  useRecalculateNutrientAnalysisMutation,
  useRecipeNutrientAnalysisQuery,
} from "../../../../../store/apis/RecipeAPIs";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";

export default function RecipeNutrientAnalysis() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const [showMore, setShowMore] = useState(false);
  const {
    data: results,
    isFetching: loading,
    // isError,
  } = useRecipeNutrientAnalysisQuery(recipeId, {
    skip: !recipeId,
  });

  const [recalculate, { isLoading: isSubmitting }] =
    useRecalculateNutrientAnalysisMutation();

  const nutrients = results?.data?.nutrients?.map((item) => ({
    ...item,
    id: item?.nutrientId,
  }));
  //  split array by half
  const splitNutrientsByMacroMicro = (array) => {
    const macros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === true
    );
    const micros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === false
    );
    const truncatedMicros = micros?.slice(0, 9);
    return [macros, showMore ? micros : truncatedMicros];
  };

  const splitNutrientDetailsByMacroMicro = (array) => {
    const macros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === true)
    );
    const micros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === false)
    );
    return [macros, micros];
  };

  const nutrientsData = splitNutrientsByMacroMicro(nutrients);

  const detailedNutrients = results?.data?.nutrientDetails?.map((item) => ({
    ...item,
    id: `${item?.nutrientId}_${item?.ingredientId}_${crypto.randomUUID()}`,
  }));
  // Group objects by sortOrder and store it in array
  const groupByNutrientId = (array) => {
    return array?.reduce((acc, item) => {
      const { sortOrder } = item;
      if (!acc[sortOrder]) {
        acc[sortOrder] = [];
      }
      acc[sortOrder].push(item);
      return acc;
    }, {});
  };
  const groupedNutrients = Object.values(
    groupByNutrientId(detailedNutrients) || []
  );

  const nutrientsDetailsData =
    splitNutrientDetailsByMacroMicro(groupedNutrients);

  return (
    <>
      {loading ? (
        <EditSkeleton />
      ) : (
        <Card sx={{ height: { md: "90%", xl: "95%" }, overflow: "auto" }}>
          <Box
            sx={{
              padding: "5px",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}>
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: "43.65px",
                }}>
                {results?.data?.recipeName}
              </Typography>
              {/* <WarningText
              margin={"5px"}
              sx={{ marginLeft: "0px !important" }}
              text={
                "Please see Menu Instruction Booklet for Menu/Recipe Nutrient Analysis limitations."
              }
              width={"60%"}
            /> */}
              <BaseButton
                text={"Recalculate"}
                isSubmitting={isSubmitting}
                onClick={() => recalculate(recipeId)}
                sx={{ backgroundColor: "#008385" }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "19px",
                  fontWeight: "600",
                  lineHeight: "27.28px",
                }}>
                {results?.data?.servingSize
                  .replace(/[()]/g, "") // Remove the parentheses
                  .replace("ServingSize", "Serving Size")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginY: "10px",
                    width: "90%",
                  }}>
                  {nutrientsData.map((data, ind) => (
                    <Box
                      key={ind}
                      sx={{
                        width: "49%",
                      }}>
                      {data.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid black",
                          }}>
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                            }}>
                            {item?.nutrient?.name}
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              fontSize: "14px",
                            }}>
                            {item?.quantity +
                              " " +
                              item?.nutrient.unitOfMeasure}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
              <BaseButton
                sx={{
                  height: "25px",
                  width: "225px",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "15px",
                }}
                fontColor={"#000000"}
                border={"1px solid #000000"}
                onClick={() => setShowMore(!showMore)}
                text={
                  showMore
                    ? "Show Fewer Micronutrients"
                    : "Show More Micronutrients"
                }></BaseButton>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  color: DMStyles.Black,
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "#C8DCE3",
                }}>
                Details By Nutrients
              </Typography>

              <Box
                sx={{
                  paddingX: "10px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                {nutrientsDetailsData?.map((nutrient, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "49%",
                      border: "2px solid black",
                      borderRadius: "10px",
                      padding: "16px",
                    }}>
                    <div style={{ fontSize: "24px", fontWeight: "700" }}>
                      {i === 0 ? "Macronutrients" : "Micronutrients"}
                    </div>
                    {nutrient.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginY: "5px",
                          borderBottom: " 1px solid black",
                        }}>
                        <Typography
                          sx={{
                            width: "30%",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "24px",
                          }}>
                          {data[0]?.nutrient}
                        </Typography>
                        <Box
                          sx={{
                            width: "60%",
                          }}>
                          {data.map((item, ind) => (
                            <Box
                              key={ind}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <Typography
                                variant="p"
                                sx={{
                                  fontSize: "14px",
                                  lineHeight: "19px",
                                  fontWeight: "500",
                                }}>
                                {item.ingredient}
                              </Typography>
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: "14px",
                                }}>
                                {item?.nutrientQuantity.toFixed(2) + " "}
                                {item?.baseUnit === "gram"
                                  ? "g"
                                  : item?.baseUnit}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}
