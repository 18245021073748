// MUI Imports
import { Box, Grid, Typography } from "@mui/material";
//  hooks Imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// Custom Components  Imports
import GeneratedForm from "../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../shared/baseContent/BaseContent";
// Store variables Imports
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";

import {
  useGetPendingMealsQuery,
  useCreateTablesideSetupMutation,
} from "../../../store/apis/TableSideApis";
//
import dayjs from "dayjs";
// lodash Imports
import isEqual from "lodash/isEqual";
import size from "lodash/size";
// Constants Imports
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { tableSideMealFields } from "./form.constants";
import { useGetMenusBySeasonSelectQuery } from "store/apis/MenuApis";
import BaseActionBox from "components/shared/BaseActionBox";

export const StartMeal = () => {
  // Stats Declaration
  const [queries, setQueries] = useState([
    {
      name: "menuId",
      skip: true,
    },
  ]);
  // UseForm Hooks Declaration and Destructing
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = methods;
  //  Redux - State Variable Selection
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  // useForm Variable Declaration
  const seasonId = watch("seasonId");
  const menuId = watch("menuId");

  // Get Api Section
  const {
    data: tableSideData,
    isFetching: loading,
    error: hasError,
    refetch: refetchPendingMeals,
  } = useGetPendingMealsQuery(
    { facilityId: facilityId },
    { skip: !facilityId }
  );

  const { data: menuData } = useGetMenusBySeasonSelectQuery(seasonId, {
    skip: !seasonId,
  });

  // Mutation Section
  const [
    createTablesideSetup,
    { isLoading: addLoading, isSuccess: addSuccess },
  ] = useCreateTablesideSetupMutation();

  // useEffect Section
  useEffect(() => {
    setValue("menuDay", 1);
    clearErrors("menuDay");
    const menuObject = menuData?.find((data) => data.id == menuId);
    if (menuData) {
      tableSideMealFields[3].validationProps.max.value = menuObject?.daysInMenu;
      tableSideMealFields[3].validationProps.max.message = isEqual(
        menuObject?.daysInMenu,
        1
      )
        ? "Menu Day should be 1 "
        : `Menu Day Should be between 1 to ${menuObject?.daysInMenu}`;
    }
  }, [menuData, menuId]);

  useEffect(() => {
    seasonId
      ? setQueries([
          ...queries.filter((x) => x.name != "menuId"),
          {
            name: "menuId",
            query: seasonId,
            skip: !seasonId,
          },
        ])
      : null;
  }, [seasonId]);

  useEffect(() => {
    setValue("diningDate", dayjs().format(SCREEN_CONSTANTS.Date_Format));
    setValue("startTime", dayjs().format(SCREEN_CONSTANTS.Time_Format));
  }, [setValue]);

  useEffect(() => {
    if (addSuccess && !addLoading) {
      refetchPendingMeals();
    }
  }, [addLoading, addSuccess]);

  const readableDate = (data) => {
    const formatedDate = dayjs(data);
    const formattedDate = formatedDate.format("YYYY-MM-DD"); // 2023-09-14
    const formattedTime = formatedDate.format("hh:mm a");
    const finalResult = formattedDate + " " + formattedTime;
    return finalResult;
  };
  const OrderColumns = [
    {
      field: "diningDate",
      minWidth: 100,
      flex: 1,
      headerName: "Dining Date",
      renderCell: (params) => {
        const finalResult = readableDate(params.row.diningDate);
        return finalResult;
      },
    },
    {
      field: "mealName",
      // minWidth: 100,
      flex: 1,
      headerName: "Meal",
    },

    {
      field: "menuName",
      // minWidth: 100,
      flex: 1,
      headerName: "Menu",
    },
    {
      field: "day",
      // minWidth: 100,
      flex: 1,
      headerName: "Day",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "seasonName",
      // minWidth: 100,
      flex: 1,
      headerName: "Season",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "id",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      // maxWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      renderCell: (params) => {
        // const apiDate = new Date(params.row.diningDate);
        // const currentDate = new Date();
        const formatedDate = dayjs(params.row.diningDate);
        const formattedDate = formatedDate.format(SCREEN_CONSTANTS.Date_Format);
        return (
          <BaseActionBox
            index={params.row.id}
            data={tableSideData}
            hasDelete={!params.row?.isActive}
            deleteProps={{
              entityName: `${params.row?.mealName} on ${formattedDate}`,
              apiPath: "deleteMeal",
              FacilityId: facilityId,
            }}
            childrenLocation={"end"}>
            {params.row?.isActive ? "Active" : ""}
          </BaseActionBox>
        );
      },
    },
  ];
  const dateFormatter = (data) => {
    const parsedDate = dayjs(data.diningDate).format("YYYY-MM-DD");
    const parsedTime =
      typeof data.startTime === "string"
        ? data.startTime
        : dayjs(data.startTime).format(SCREEN_CONSTANTS.Time_Format);
    const combinedDateTime = `${parsedDate}T${parsedTime}`;
    // Parse the combined date and time using Day.js
    const parsedDateTime = dayjs(combinedDateTime);
    // Format the parsed date and time into the desired format
    const formattedDate = parsedDateTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
    return formattedDate;
  };
  const onSubmit = (data) => {
    const formattedDate = dateFormatter(data);
    const dataObject = {
      diningDate: formattedDate,
      facilityId: facilityId,
      mealId: data.meal,
      day: data.menuDay,
      menuId: data.menuId,
      seasonId: data.seasonId,
      now: false,
    };
    createTablesideSetup(dataObject);
  };
  return (<>
    <BaseContent
      headerText={`Start a Meal for ${facilityName}`}
      disableHeight={true}>
      <Box sx={{
        height: { md: "86vh" }
      }}>
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: " 3px 4px 28px -8px rgba(0, 0, 0, 0.25);",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            position: "relative"
          }}>
          <Box
            sx={{
              width: "80%",
              marginY: "10px"
            }}>
            <Grid
              container
              spacing={2}
              sx={{
                width: "100%",
                margin: "1px",
              }}>
              <GeneratedForm
                list={tableSideMealFields}
                queries={queries}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                marginY: "10px",
                justifyContent: "flex-end"
              }}>
              <BaseSubmitButton
                text="Start a Meal"
                endIcon="restaurant"
                onClick={handleSubmit(onSubmit)}
                isSubmitting={addLoading}
              />
            </Box>
          </Box>
        </Box>
        {!isEqual(size(tableSideData), 0) ? (
          <Box sx={{
            marginY: "10px"
          }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                marginTop: "10px",
                fontWeight: "bold",
              }}>
              Queued Meals{" "}
            </Typography>

            <Box sx={{
              marginY: "10px"
            }}>
              <BaseDataGrid
                rows={tableSideData}
                loading={loading}
                columns={OrderColumns}
                error={hasError}
                // getRowHeight={() => "auto"}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                    {
                      py: "22px",
                    },
                }}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </BaseContent>
  </>);
};
