import {
  INVALID_EMAIL_VALIDATION,
  REQUIRED_ERROR,
  INVALID_US_PHONE_VALIDATION,
} from "utils/errorMessages";

export const ContactUsElements = [
  {
    label: "Address",
    icon: "place",

    details: [
      {
        // Label: "Address: ",
        Value: "8446 Page Avenue, St. Louis, MO 63130",
      },
    ],
  },
  {
    label: "Contact Number",
    icon: "phone",

    details: [
      {
        Label: "Toll Free: ",
        Value: "800.544.3059",
      },
      {
        Label: "Local: ",
        Value: "314.426.0579",
      },
      {
        Label: "Fax: ",
        Value: "314.423.9825",
      },
      {
        Label: "Text/SMS: ",
        Value: "314.833.8850",
      },
    ],
  },
];
// export const ContactUsElements = {
//   address: {
//     Label: "Address: ",
//     Value: "8446 Page Avenue, St. Louis, MO 63130",
//   },
//   // email: {
//   //   Label: "Email: ",
//   //   Value: "support@diningrd.com",
//   // },
//   tollFreePhone: {
//     Label: "Toll Free: ",
//     Value: "800.544.3059",
//   },
//   localPhone: {
//     Label: "Local: ",
//     Value: "314.426.0579",
//   },
//   faxPhone: {
//     Label: "Fax: ",
//     Value: "314.423.9825",
//   },
//   textMessage: {
//     Label: "Text/SMS: ",
//     Value: "314.833.8850",
//   },
// };

export const ContactUsBlurb =
  "Please fill in the form below. If you need immediate assistance, please contact us at 800.544.3059. All fields must be completed.";

export const ContactUsFields = [
  {
    title: "Community",
    props: {
      label: "",
      id: "facility",
      name: "facility",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Community"),
    },
  },
  {
    title: "Name",
    props: {
      label: "",
      id: "name",
      name: "name",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
    },
  },
  {
    title: "Email",
    props: {
      label: "",
      id: "email",
      name: "email",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Email"),
      pattern: INVALID_EMAIL_VALIDATION(),
    },
  },
  {
    title: "Phone Number",
    props: {
      label: "",
      id: "phoneNumber",
      name: "phoneNumber",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Phone Number"),
      pattern: INVALID_US_PHONE_VALIDATION(),
    },
  },
  {
    title: "Subject",
    props: {
      label: "",
      id: "subject",
      name: "subject",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Subject"),
    },
  },
  {
    title: "Message",
    props: {
      label: "",
      id: "message",
      name: "message",
      defaultValue: "",
      multiline: true,
      rows: 4,
    },
    validationProps: {
      required: REQUIRED_ERROR("Message"),
    },
  },
];
