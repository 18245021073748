// Mui Imports
import { Grid, FormControl, Button, Typography, Box } from "@mui/material";
// Hooks Imports
import React, { useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
// Css Imports
import "./UserFacilities.css";
// Mutation and Query Imports
import {
  useAddUserFacilityMutation,
  useAvailableUserFacilitiesQuery,
  useGetCurrentUserQuery,
  useUserFacilitiesQuery,
} from "../../../../../../store/apis/UserApis";
// Custom Component Imports
import ErrorPage from "../../../../../shared/errorPage/ErrorPage";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { MultipleRemoveButton } from "components/admin/shared/MultipleRemoveButton";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import { Add } from "@mui/icons-material";

function UserFacilities() {
  // States Declaration
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [debouncedValue, setDebouncedValue] = useState("");
  const [availableSearchTerm, setAvailableSearchTerm] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Hooks Declaration
  const [searchParams] = useSearchParams();
  const currentUserId = searchParams.get("id");
  // Query Section
  const { data: currentUser, isError: currentUserError } =
    useGetCurrentUserQuery(currentUserId, {
      skip: !currentUserId,
      refetchOnMountOrArgChange: true,
    });
  const {
    data: selectedFacilities,
    isFetching: loadingSelected,
    isError: selectedError,
    isSuccess: selectedSuccess,
  } = useUserFacilitiesQuery(currentUserId, { skip: !currentUserId });
  const {
    data: pagedAvailableFacilities,
    isFetching: loadingAvailable,
    isError: availableError,
  } = useAvailableUserFacilitiesQuery(
    {
      searchTerm: debouncedValue,
      skip: paginationModel.pageSize * paginationModel.page,
      take: paginationModel.pageSize,
    },
    { skip: !selectedFacilities || !selectedSuccess }
  );
  // Mutation Section
  const [addUserFacility] = useAddUserFacilityMutation();

  const availableFacilities = useMemo(
    () =>
      pagedAvailableFacilities?.items?.filter((res) => {
        return !selectedFacilities?.some((x) => x.facilityId == res.id);
      }),
    [pagedAvailableFacilities?.items]
  );

  const availableFacilitiesCount =
    pagedAvailableFacilities?.totalCount -
    pagedAvailableFacilities?.items?.filter((res) => {
      return selectedFacilities?.some((x) => x.facilityId == res.id);
    }).length;

  const loadError = currentUserError || selectedError;

  function handleAdd(ids) {
    ids.forEach((id) => {
      addUserFacility({ facilityId: id, userId: currentUser?.id });
    });
  }

  // Column Data for Data Grid Mui
  const selectedColumns = [
    {
      field: "facilityName",
      headerName: "Community",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "corporateGroupName",
      headerName: "Corporate Group",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            hasDelete={true}
            deleteProps={{
              entityName: `${params.row?.userName} - ${params.row?.facilityName}`,
              apiPath: "deleteUserFacility",
              title: "User Facility",
            }}
          />
        );
      },
    },
  ];
  const availableColumns = [
    {
      field: "name",
      headerName: "Community",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "corporateGroup",
      headerName: "Corporate Group",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox childrenLocation={"end"}>
            <Button
              color="primary"
              icon="true"
              onClick={() => handleAdd([params.row.id])}
              variant="contained">
              {<Add />}
            </Button>
          </BaseActionBox>
        );
      },
    },
  ];

  return (<>
    {loadError === true ? (
      <ErrorPage {...loadError} />
    ) : (
      <>
        {currentUser && (
          <Box sx={{
            display: "flex"
          }}>
            <Typography
              className="userFacilityTitle"
              sx={{
                color: "primary",
                fontSize: "18px"
              }}>
              Change Community assignment for{" "}
              {currentUser?.firstName + " " + currentUser?.lastName}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Box sx={{ width: "50%" }}>
            <FormControl>
              <BaseSearch
                sx={{
                  marginLeft: "12px",
                }}
                placeholder="Search Available Communities"
                setPaginationModel={setPaginationModel}
                value={availableSearchTerm}
                setSearchValue={setAvailableSearchTerm}
                setDebouncedValue={setDebouncedValue}
              />
            </FormControl>
          </Box>
        </Box>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <div className="actionsRow">
            <div className="leftActions">
              <MultipleRemoveButton selectedRowIds={selectedRowIds} />
            </div>
          </div>
          <Grid item md={6} sx={{ height: "100%" }}>
            <BaseDataGrid
              loading={loadingSelected}
              columns={selectedColumns}
              autoHeight={false}
              height={{ md: "40vh", xl: "52vh" }}
              onSelectionModelChange={(ids) => {
                setSelectedRowIds(ids);
              }}
              rows={selectedFacilities}
              error={selectedError}
            />
          </Grid>
          <Grid item md={6} sx={{ height: "100%" }}>
            <BaseDataGrid
              rows={availableFacilities}
              rowCount={availableFacilitiesCount || 0}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              loading={loadingAvailable}
              columns={availableColumns}
              autoHeight={false}
              height={{ md: "40vh", xl: "52vh" }}
              // onSelectionModelChange={(ids) => {
              //   setAvailableRowIds(ids);
              // }}
              error={availableError}
            />
          </Grid>
        </Grid>
      </>
    )}
  </>);
}

export default UserFacilities;
