/******* THIS IS NOT COMPLETE ... USING MEAL EXTRA AS BASE TO START */

import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useAddResidentAllergyDislikesMutation,
  useGetResidentAllergySubstitutionsSelectBoxQuery,
  useGetResidentDislikesSubstitutionsSelectBoxQuery,
} from "../../../../../store/apis/ResidentAllergyDislikeApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useForm } from "react-hook-form";
import BaseInput from "components/shared/baseForm/BaseInput";
import { BaseButton } from "components/shared/BaseButton";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import ConfirmModal from "./ConfirmModal";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  width: "35vw",
  boxShadow: 24,
  p: 1,
};

export default function AddResidentSubstitutions({
  open,
  onClose,
  allergy,
  dislike,
  data,
  menuName,
}) {
  const menuCategoryId = data?.menuCategoryId;
  const menuId = data?.menuId;
  const residentId = data?.residentId;

  const [residentAllergies, setResidentAllergies] = useState([]);
  const [residentDislikes, setResidentDislikes] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const noSubNeed = watch("noSubNeeded");
  const keepOrig = watch("keepOriginalMenu");
  const areBothCheckboxes = noSubNeed && keepOrig;

  const { data: substitutionsAllergy, isLoading: substitutionsAllergyLoading } =
    useGetResidentAllergySubstitutionsSelectBoxQuery(
      {
        menuCategoryId: menuCategoryId,
        menuId: menuId,
        residentId: residentId,
      },
      {
        skip: !menuCategoryId || !menuId || !allergy || !residentId,
      }
    );

  const { data: substitutionsDislike, isLoading: substitutionsDislikeLoading } =
    useGetResidentDislikesSubstitutionsSelectBoxQuery(
      {
        menuCategoryId,
        menuId,
        residentId,
      },
      {
        skip: !menuCategoryId || !menuId || !dislike || !residentId,
      }
    );
  const loading = substitutionsAllergyLoading || substitutionsDislikeLoading;

  const [addResidentAllergyDislike, { isLoading: addResidentLoadaing }] =
    useAddResidentAllergyDislikesMutation();

  useEffect(() => {
    if (substitutionsAllergy != null) {
      setResidentAllergies(substitutionsAllergy);
    }
  }, [substitutionsAllergy]);

  useEffect(() => {
    if (substitutionsDislike != null) {
      setResidentDislikes(substitutionsDislike);
    }
  }, [substitutionsDislike]);

  const allergyColumns = [
    {
      field: "menuItemName",
      minWidth: 25,
      flex: 2,
      headerName: `Option ( ${data?.menuCategoryName} ) `,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <AddCircleIcon
              cursor="pointer"
              color="primary"
              onClick={() => {
                addResidentAllergyDislike({
                  id: data.id,
                  originalRecipeId: data.originalRecipeId,
                  residentId: data?.residentId,
                  menuId: data?.menuId,
                  foodTypeId: data?.foodTypeId,
                  menuItemId: data?.menuItemId,
                  menuDayId: data?.menuDayId,
                  allergyId: data?.allergyId,
                  substituteId: params?.row?.menuItemId,
                  forBreakfast: data?.forBreakfast,
                  forLunch: data?.forLunch,
                  forSupper: data?.forSupper,
                  isDislike: false,
                  isAllergy: true,
                  allergyOrDislikeId: data?.allergyOrDislikedId,
                  menuCategoryId: data?.menuCategoryId,
                  noSubNeeded: false,
                  keepOriginalMenu: false,
                  specialNotes: "",
                });
                onClose(onClose);
              }}
            />
          </div>
        );
      },
    },
  ];

  const dislikesColumns = [
    {
      field: "menuItemName",
      minWidth: 25,
      flex: 2,
      headerName: `Option ( ${data?.menuCategoryName} ) `,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <AddCircleIcon
              cursor="pointer"
              color="primary"
              onClick={() => {
                addResidentAllergyDislike({
                  id: data.id,
                  originalRecipeId: data?.originalRecipeId,
                  residentId: data?.residentId,
                  menuId: data?.menuId,
                  foodTypeId: data?.foodTypeId,
                  menuItemId: data?.menuItemId,
                  menuDayId: data?.menuDayId,
                  allergyId: data?.allergyId,
                  substituteId: params?.row?.menuItemId,
                  forBreakfast: data?.forBreakfast,
                  forLunch: data?.forLunch,
                  forSupper: data?.forSupper,
                  isDislike: true,
                  isAllergy: false,
                  allergyOrDislikeId: data?.allergyOrDislikedId,
                  menuCategoryId: data?.menuCategoryId,
                  noSubNeeded: false,
                  keepOriginalMenu: false,
                  specialNotes: "",
                });
                onClose(onClose);
              }}
            />
          </div>
        );
      },
    },
  ];

  // updated

  const handleClose = () => {
    onClose(onClose);
  };

  const closeModal = () => {
    setConfirmModal(false);
  };

  const onSubmit = (rawData) => {
    if (dislike) {
      addResidentAllergyDislike({
        id: data.id,
        originalRecipeId: data?.originalRecipeId,
        residentId: data?.residentId,
        menuId: data?.menuId,
        foodTypeId: data?.foodTypeId,
        menuItemId: data?.menuItemId,
        menuDayId: data?.menuDayId,
        allergyId: data?.allergyId,
        substituteId: null,
        forBreakfast: data?.forBreakfast,
        forLunch: data?.forLunch,
        forSupper: data?.forSupper,
        isDislike: true,
        isAllergy: false,
        allergyOrDislikeId: data?.allergyOrDislikedId,
        menuCategoryId: data?.menuCategoryId,
        noSubNeeded: rawData?.noSubNeeded,
        keepOriginalMenu: rawData?.keepOriginalMenu,
        specialNotes: rawData?.specialNotes ?? "",
      });
    }
    if (allergy) {
      addResidentAllergyDislike({
        id: data.id,
        originalRecipeId: data?.originalRecipeId,
        residentId: data?.residentId,
        menuId: data?.menuId,
        foodTypeId: data?.foodTypeId,
        menuItemId: data?.menuItemId,
        menuDayId: data?.menuDayId,
        allergyId: data?.allergyId,
        substituteId: null,
        forBreakfast: data?.forBreakfast,
        forLunch: data?.forLunch,
        forSupper: data?.forSupper,
        isDislike: false,
        isAllergy: true,
        allergyOrDislikeId: data?.allergyOrDislikedId,
        menuCategoryId: data?.menuCategoryId,
        noSubNeeded: rawData?.noSubNeeded,
        keepOriginalMenu: rawData?.keepOriginalMenu,
        specialNotes: rawData?.specialNotes ?? "",
      });
    }
    onClose(onClose);
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    setConfirmModal(false);
  };

  return (<>
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        {loading ? (
          <EditSkeleton />
        ) : (
          <>
            <Box>
              <CloseIcon
                sx={{
                  float: "right",
                  border: "1px solid",
                  borderRadius: "50%",
                  borderColor: "primary.main",
                  padding: "5px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </Box>
            <>
              <Box sx={{ padding: "5px", width: "100%" }}>
                <Box sx={{
                  marginY: 1
                }}>
                  {allergy && residentAllergies.length > 0 ? (
                    <>
                      <Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "10px"
                          }}>
                          Select Substitution for {menuName}
                        </Typography>
                        <BaseDataGrid
                          rows={residentAllergies ?? []}
                          columns={allergyColumns}
                          loading={substitutionsAllergyLoading}
                          autoHeight={false}
                          height={{ md: "40vh", lg: "45vh", xl: "50vh" }}
                          width="100%"
                        />
                      </Box>
                      <Box>
                        <Grid container spacing={0.5}>
                          <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                              sx={{
                                bgcolor: "#f6f6f6",
                                borderRadius: "5px",
                                padding: "10px",
                                marginTop: "5px",
                              }}>
                              <BaseCheckBox
                                control={control}
                                label="No sub needed, remove menu item on MealCard"
                                name={"noSubNeeded"}
                                errors={errors}
                              />
                            </Box>
                          </Grid>
                          <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                              sx={{
                                bgcolor: "#f6f6f6",
                                borderRadius: "5px",
                                padding: "10px",
                                margin: "2px",
                              }}>
                              <BaseCheckBox
                                control={control}
                                label="Keep original menu item on MealCard"
                                name="keepOriginalMenu"
                                errors={errors}
                              />
                            </Box>
                          </Grid>
                          {watch("keepOriginalMenu") ? (
                            <>
                              {" "}
                              <Grid item sx={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography variant="p" component="p">
                                  Special Note
                                </Typography>
                                <span>
                                  <Typography variant="p" component="p">
                                    (if applicable)
                                  </Typography>
                                </span>
                              </Grid>
                              <Grid item sx={6} sm={6} md={6} lg={6} xl={6}>
                                <BaseInput
                                  name="specialNotes"
                                  id="specialNotes"
                                  control={control}
                                  errors={errors}
                                  validationProps={{
                                    required: {
                                      value: true,
                                      message: "Special Notes is required",
                                    },
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Special Notes must not exceed 25 characters",
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                          ) : null}
                          {areBothCheckboxes ? (
                            <Grid
                              item
                              sx={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}>
                              <Typography
                                variant="p"
                                component="p"
                                sx={{
                                  color: "red"
                                }}>
                                Select only one of the options above
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={[{
                              display: "flex",
                              justifyContent: "end"
                            }, 12]}>
                            <BaseButton
                              text="Save"
                              onClick={() => setConfirmModal(true)}
                              disabled={
                                areBothCheckboxes || (!noSubNeed && !keepOrig)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null}
                  {dislike && residentDislikes.length > 0 ? (
                    <>
                      <Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "10px"
                          }}>
                          Select Substitution for {menuName}
                        </Typography>
                        <BaseDataGrid
                          rows={residentDislikes ?? []}
                          columns={dislikesColumns}
                          loading={substitutionsDislikeLoading}
                          autoHeight={false}
                          height={{ md: "40vh", lg: "45vh", xl: "45vh" }}
                          width="100%"
                        />
                      </Box>
                      <Box>
                        <Grid container spacing={0.5}>
                          <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                              sx={{
                                bgcolor: "#f6f6f6",
                                borderRadius: "5px",
                                padding: "10px",
                                marginTop: "5px",
                              }}>
                              <BaseCheckBox
                                control={control}
                                label="No sub needed, remove menu item on MealCard"
                                name={"noSubNeeded"}
                                errors={errors}
                              />
                            </Box>
                          </Grid>
                          <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                              sx={{
                                bgcolor: "#f6f6f6",
                                borderRadius: "5px",
                                padding: "10px",
                                margin: "2px",
                              }}>
                              <BaseCheckBox
                                control={control}
                                label="Keep original menu item on MealCard"
                                name="keepOriginalMenu"
                                errors={errors}
                              />
                            </Box>
                          </Grid>
                          {watch("keepOriginalMenu") ? (
                            <>
                              {" "}
                              <Grid item sx={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography variant="p" component="p">
                                  Special Note
                                </Typography>
                                <span>
                                  <Typography variant="p" component="p">
                                    (if applicable)
                                  </Typography>
                                </span>
                              </Grid>
                              <Grid item sx={6} sm={6} md={6} lg={6} xl={6}>
                                <BaseInput
                                  name="specialNotes"
                                  id="specialNotes"
                                  control={control}
                                  errors={errors}
                                  validationProps={{
                                    required: {
                                      value: true,
                                      message: "Special Notes is required",
                                    },
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Special Notes must not exceed 25 characters",
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                          ) : null}
                          {areBothCheckboxes ? (
                            <Grid
                              item
                              sx={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}>
                              <Typography
                                variant="p"
                                component="p"
                                sx={{
                                  color: "red"
                                }}>
                                Select only one of the options above
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={[{
                              display: "flex",
                              justifyContent: "end"
                            }, 12]}>
                            <BaseButton
                              text="Save"
                              isSubmitting={addResidentLoadaing}
                              onClick={handleSubmit(onSubmit)}
                              disabled={
                                areBothCheckboxes || (!noSubNeed && !keepOrig)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null}
                  {residentAllergies.length == 0 &&
                  residentDislikes.length == 0 &&
                  !loading ? (
                    <Typography sx={{
                      color: "error"
                    }}>
                      Please visit our PlateFul service to access the Menu
                      Planner in order to add a substitution option for{" "}
                      {data?.menuCategoryName}.
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </>
          </>
        )}
      </Box>
    </Modal>
    {confirmModal ? (
      <ConfirmModal
        open={confirmModal}
        onClose={closeModal}
        agree={handleSave}
      />
    ) : null}
  </>);
}
